.my-bet-list h3.heading {
    background-color: #04978b;
    color: white;
    margin: 0px;
    padding: 15px 17px;
    padding: 12px;
    margin-bottom: 1px;
    border-radius: 5px 5px 0 0;
	font-size: 16px;
}
#form-bet {
    font-size: 0.9em;
    border: 1px solid #d0dcdd;
    display: none;
    padding: 10px;
    z-index: 99;
    background: #fefefe;
    width: 100%;
}
.my-bet-list .box .box-header,#tipster-box .box-header {
    background: #e4e4e4;
    color: black;
    padding: 10px;
    float: left;
    width: 100%;
}
.float-right {
    float: right;
}
.float-left {
    float: left;
}
.my-bet-list .box .box-header a {
    color: #04978b;
}
.my-bet-list .box .box-content {
    border: 1px solid #d0dcdd;
    border-top: none;
    width: 100%;
    float: left;
    background-color: white;
}
#mybet-list .bet-item, #mybet-list-success .bet-item {
    /*border-bottom: solid 1px #dddddd;
	background-color: #04978b05;
    padding: 10px;
	padding: 5px 10px;*/
	padding: 5px;
	
}
.my-bet-list .box .bet-item .match {
    float: left;
    width: 70%;
    width: 80%;
}
.bet-item span {
    display: block;
    padding: 4px 2px;
    float: left;
    border-right: 1px solid transparent;
}
.bet-item .match span {
    display: block;
    padding: 2px;
    float: none;
}
.match .bet-type {
    font-weight: 700;
}
.bet-item .match span {
    display: block;
    padding: 2px;
    float: none;
}
.bet-item span:last-child {
    border: none;
}
.bet-item span {
    display: block;
    padding: 4px 2px;
    float: left;
    border-right: 1px solid transparent;
}
.bet-item .amount {
    text-align: right;
}
.my-bet-list .box .bet-item .amount {
    width: 45px;
    /*padding-top: 8px;*/
}
.bet-item span {
    display: block;
    padding: 4px 2px;
    float: left;
    border-right: 1px solid transparent;
}
.bet-item span:last-child {
    border: none;
}
.clear-fix:after {
    content: ".";
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}
.create-pick {
    width: 100%;
    float: left;
    text-align: center;
    margin: 15px 0;
    margin: 0;
}
.create-pick a.btn {
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
    border: solid 1px #dddddd;
    border-radius: 5px;
}
div.bet-league {
    padding: 15px;
    padding: 12px;
    background: #04978b;
    color: white;
    cursor: pointer;
    border-bottom: solid 1px white;
    border-radius: 5px 5px 0 0;
}
div.bet-league a {
    color: white;
    text-transform: uppercase;
    font-weight: 500;
}
 a.collapse {
     background-image: url('https://www.coopbet.com/media/collapse.png');
    width: 14px;
    cursor: pointer;
}
i.collapse {
     background-image: url('https://www.coopbet.com/media/collapse.png');
    width: 14px;
    cursor: pointer;
	display: inherit;
	float: right;
}
i.expand {
    background-image: url('https://www.coopbet.com/media/expand.png');
    width: 14px;
    cursor: pointer;
	display: inherit;
	float: right;
}
.table_bound {
    background-color: #fff;
	width: 100%;
	display:block;
}
table.oddmove {
    border-collapse: collapse;
    border: 1px solid #dddddd;
    border-top: none;
    width: 100%;
}
table.oddmove thead {
    background: #10b2a5;
    color: white;
}
table.oddmove thead td {
    padding: 5px 0;
    border: none;
	text-align:center;
}
table.oddmove .odd-row {
    border-bottom: solid 1px #dddddd;
}
.oddmove td div {
    padding: 5px;
}
a.bet-link {
    border: 1px solid #ccc;
    background: #eee;
    padding: 3px;
    font-weight: 700;
	cursor:pointer;
}

#tipster-box .box-content {
    border: 1px solid #d0dcdd;
    border-top: none;
    width: 100%;
    float: left;
    background-color: white;
}
#tips-list .bet-item{
    border-bottom: solid 1px #dddddd;
    padding: 10px;
}
#tips-list .tips-type{
	    text-transform: uppercase;
}
.tips-type .odd{
	    color: #04978b;
}
#tips-list .match{
	font-size:12px;
}
.tip-name{
	font-weight: bold;
    display: inline!important;;
}
.lb-tips{
	/*display: inline;*/
	padding-top:10px;
    padding-right: 5px;
    font-weight: 300;
	font-size:12px;
}
.ip-tips{
	display: inline;
    width: 50%;
    padding: 6px;
}
#tips-list .rt{
	text-align: right;
    font-weight: bold;
    font-size: 12px;
}
.ckt label{
	font-size:12px!important;
}
#tips-list .ckt{
	margin-bottom:0px;
}
#tips-list .rdg{
	 margin: 3px 0px;
	font-size:11px!important;
}
#tips-list .rdg input{
	margin-top: 1px!important;
}
.btn-choose{
	background-color:#05978b;color:#fff;
	font-size:11px;
	padding:3px 10px;
}
.btn-close{
	background-color:#05978b;color:#fff;
	font-size:11px;
	padding:3px 10px;
	float:right;
}
.checked {
    width: 16px;
    height: 16px;
    margin: 5px;
    /*background: url('https://www.coopbet.com/media/check_ap.png') no-repeat 0px 0px;*/
}
.remove-tip{
	font-size: 16px;
    padding-top: 3px;
    color: red;
    cursor: pointer;
}
.bet-item .comments{
	float: left;
    width: 100%;
}
.bet-item .note-comment{
	padding-left:3px
}
.bet-link:hover{
	background-color:orange;
	color:green;
}
/*----loader*/
.loader {
  position: absolute;
  left: 60%;
  top: 50%!important;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: -75px 0 0 -75px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*------------*/
.loadern {
    position: inherit;
    left: 0%;
    top: 50%;
    z-index: 1;
    width: 18px;
    height: 18px;
    margin: 0;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    -webkit-animation: spins 2s linear infinite;
    animation: spins 2s linear infinite;
}
@-webkit-keyframes spins {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spins {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*--------------*/
#tips-list .react-numeric-input{
	width: 50%;    position: absolute!important;
}
.state-tips{
	width:100%;
	 height: 30px;
}
#tips-list .react-numeric-input >b:first-of-type{
	top: 4px!important;
}
#tips-list .react-numeric-input >b:last-child{
	bottom: 4px!important;
}
.league{
	float: right;
    padding-right: 10px;
    padding-top: 8px;
}
#time-play .dropdown-menu > li > a{
	text-transform: capitalize;
	color: #04978b;
}
#time-play .dropdown-menu{
	min-width:300px!important;
}
#time-play .dropdown-menu .left{
	float:left;
}
#time-play .dropdown-menu .right{
	text-align:right;
}
#time-play,#name-play{
	margin-top:-4px;
	float:right;
}
#time-play #dropdown-size-large,#name-play #dropdown-size-large{
	background-color: #fff;
	color: #1c7d74;
	border-color: #1c7d74;
	text-transform: capitalize;
    font-size: 16px;
	    padding: 5px 10px;
}
#time-play .dropdown-menu > .active > a,#time-play .dropdown-menu > .active > a:focus,#time-play .dropdown-menu > .active > a:hover,
#name-play .dropdown-menu > .active > a,#name-play.dropdown-menu > .active > a:focus,#name-play .dropdown-menu > .active > a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #04978b;
    outline: 0;
}
#time-play .dropdown-menu .right >span{
	background-color: #00ffff;
    color: #009688;
    border-radius: 50%;
    padding: 4px;
	width: 25px;
    height: 23px;
    display: inline-block;
	text-align:center;
	font-size:11px;

}
#time-play .dropdown-menu .active .right >span {
    background-color: #fe9200;
    color: #009688;
    border-radius: 50%;
    padding: 4px;
	width: 25px;
    height: 23px;
    display: inline-block;
	font-size:11px;
}
#time-play .dropdown-menu::before {
    top: -8px;
    border-style: solid;
    border-color: transparent transparent #04978b;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    left: 15px;
} 
#name-play .dropdown{
	display: contents!important;
}
#name-play .dropdown-menu{
	width: 70%;
    margin-left: 22%;
    top: 90%;
}
#name-play{
	margin-left:10px;
}
.btn-name{
	outline:none!important;
}
.btn-name .caret{
	margin-left:5px;
}
.btn-name,.btn-name:hover,.btn-name:active:hover,
.btn-name.focus, .btn-name:focus {
    color: #1c7d74!important;
    background-color: #fff;
    border-color: #1c7d74!important;
	    font-size: 16px;
    text-transform: capitalize;
	    padding: 5px 10px;
}
#box-play {
    display: block;
    position: absolute;
    top: 50%;
    right: 8%;
    z-index: 1000;
    display: none;
    float: right;
    min-width: 84%;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
#box-play::before {
    top: -8px;
    border-style: solid;
    border-color: transparent transparent #04978b;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    right: 15px;
} 
#name-play .dropdown {
    position: relative;
    display: inline-block;
}
#content-dropdown {
    padding: 5;
    min-width: 300px!important;
    background-color: #2b486d;
    width: 780px;
    z-index: 100;
	margin-top:3px;
}

#name-play .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #fff;
    min-width: 100px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}
#content-dropdown:before {
    top: -8px;
    border-style: solid;
    border-color: transparent transparent #04978b;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    right: 15px;
}
.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #fff;
    cursor: default;
    background-color: #1c7d74;
    border: 1px solid #1c7d74;
    border-bottom-color: transparent;
}
#tab-today{
	padding:15px;background: #fff;
}
#tab-today .bet-league {
    padding: 8px;
    background: #26a69a;
    color: white;
    cursor: pointer;
    border-bottom: solid 1px white;
    border-radius: 5px 5px 0 0;
	font-size:12px;
}
.header-tab{
	background-color: #f6f6f6;    
    font-size: 14px;
    color: #1f595d;
    font-weight: bold;
}
.header-title{
	padding: 10px 15px;
}
.tab-content .row{
	margin:0px;
}
#tab-today .box-legue{
	 border: 1px solid #30d0c1;
}
.pd0{
	padding:0px!important;
}
.blue-hoki.btn:active:hover, .blue-hoki.btn.active:hover {
    background-color: #526781;
}

.blue-hoki.btn:active, .blue-hoki.btn.active {
    background-image: none;
    background-color: #4e627b;
}
.blue-hoki.btn {
    color: #FFFFFF;
    background-color: #67809F;
}
#tab-today .checkbox{
	display: inline-block;width:85%;
}
.btn-r{
	float: right;
    font-size: 12px;
    padding: 3px 5px;
    margin-top: 8px;
}
.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid #1c7d74;
    border-radius: 4px 4px 0 0;
    background: #ffffff;
    color: #1c7d74;
}
#tab-today .bet-items{
	margin-bottom:10px;
}
#tab-today i.expand {
    background-image: url(https://www.coopbet.com/media/expand.png);
    width: 14px;
    cursor: pointer;
    display: inherit;
    float: left!important;
    margin-right: 6px;
}
#tab-today i.collapse {
    background-image: url(https://www.coopbet.com/media/collapse.png);
    width: 14px;
    cursor: pointer;
    display: inherit;
    float: left!important;
    margin-right: 6px;
}
.chk-all{
	float:right;margin-right: 25px;
}
.chk-all .checkbox{
	width: auto;
    margin: 0px;
}
.chk-all input{
	position: absolute;
    margin-left: 20px!important;
    float: left;
}
.text-center{
	text-align:center;
}
.green.btn {
    color: #FFFFFF;
    background-color: #26a69a;
}
.green.btn:active:hover, .green.btn.active:hover {
    background-color: #1c7d74;
}

.green.btn:active, .green.btn.active {
    background-image: none;
    background-color: #1b746c;
}
.green.btn:hover, .green.btn:focus, .green.btn:active, .green.btn.active {
    color: #FFFFFF;
    background-color: #1f897f;
}
#content-dropdown .scroller {
    min-height: 300px !important;
    height: inherit !important;
    max-height: 500px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.competitions {
    margin-top: -4px;
    float: right;
	margin-left: 10px;
}
.btn-gd, .btn-gd:hover, .btn-gd:active:hover, .btn-gd.focus, .btn-gd:focus {
    color: #1c7d74!important;
    background-color: #fff;
    border: 1px solid #1c7d74!important;
    font-size: 16px;
    text-transform: capitalize;
    padding: 5px 10px;
    border-radius: 6px;
    line-height: 1.3333333;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    display: inherit;
}
.tab-content{
	border: 1px solid #ddd;
}
.text-center a{
	margin-bottom:5px;
}
div.bet-title a {
    color: white;
    font-weight: 500;
}
div.bet-title {
    padding: 10px;
    background: #04978b;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    border-radius: 5px 5px 0 0;
}
.bet-title input {
    color: #04978b!important;
}
.bet-title .div-month, .bet-title .div-quarter, .bet-title .div-year{
	display: inline-block;
}
.calendar-container {
    width: 180px!important;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background: #04978b!important;
    border: 2px solid #eee;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.selected_date_mp {
    font-size: 18px!important;
    color: white!important;
    font-weight: bold;
}
.col_mp:hover {
    color: #04978b!important;
    background-color: #fff!important;
    cursor: pointer;
	border-radius:5px;
}
.selected_cell {
    background-color: #fff!important;
    font-style: italic;
    color: #04978b!important;
	border-radius:5px;
}
.bet-title input {
    color: #04978b!important;
    width: 52px;
    border-radius: 5px;
}
.profile-box span.label {
    font-weight: 100;
    width: 120px;
    float: left;
    font-size: 1em;
    display: block;
    color: black;
}
.profile-box span.num {
    font-weight: 700;
    float: left;
    font-size: 1em;
}
.profile-box .data {
    background-color: white;
    border: solid 1px #dddddd!important;
    border-top: none!important;
	margin:0px!important;
}

.btt1 {
    border-top: solid 1px #dddddd;
    padding: 5px 0px!important;
}
.profile-box span.won, .profile-box span.draw, .profile-box span.lost {
    width: 50px;
    display: inline-block;
    color: white;
    padding: 4px 0;
    border-radius: 10px;
}
span.won {
    background-color: #e20000;
}
.year-input{
	width:53px!important;
}
.year-picker .picker-panel {
    top: 28px;
    left: 0px!important;
    background: #04978b!important;
    z-index: 2!important;
    width: 180px!important;
}
.year-picker .nav-button, .current-year {
    color: rgb(255, 255, 255)!important;
}
.year-picker .input-icon-calendar{
	display:none;
}
.year-picker{
	width:70px!important;
}
.year-picker .input-icon-close{
	right:18px!important;
}
.selected-year{
    background: #fff!important;
    color: #04978b!important;
    border: 1px solid transparent;
}
	
.fixed {
 position: -webkit-sticky;
  position: sticky;
  top:95px;
  right: 0px;
 /* pointer-events: none;
  background: transparent;*/
}
#tip-item{
	border-bottom: 1px solid #dddddd;
	padding: 0px 10px;
}
#tip-item .comments{
	    padding-bottom: 5px;
}
.br-bt{
	    border-top: 1px dashed #ddd;
}
.mgbt10{
	margin-bottom:10px;
}
.tool-ball.active, .tool-setting.active {
    background-color: #e9ecef;
}
.tool-ball {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 15px;
	border: 1px solid #f1f3f252;
	cursor: pointer;
}
.icon-tool {
    font-size: 20px;
    margin-top: 3px;
    margin-right: 10px;
}
.tool-ball-title {
    display: inline;
    vertical-align: bottom;
}
.mgt10{
	margin-top:10px;
}
.chk-select-all{
	float: right;
    margin-bottom: -40px;
}
.my-bet-list .box .box-header{
	font-size:13px;
	padding: 4px 10px!important;
}
.icon-tool-sm {
    font-size: 16px;
    margin-top: 1px;
    margin-right: 5px;
}
.bet-item .checked{
	width: 45px;
    text-align: center;
	margin:0;
	padding-top:0;
}
.txtr{
	text-align:right;
}
.mgbt10{
	margin-bottom:10px;
}
.pdt3{
	padding-top:3px!important;
}
.mgbt7{
	margin-bottom:7px!important;
}
#mybet-list-success{
	height: 170px!important;
    overflow-y: scroll!important;
    overflow-x: hidden;
    width: 100%;
    min-height: 100px!important;
}
#mybet-list-success::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#04978b),color-stop(.6,#04978b),to(#04978b));
    border-radius: 10px;
}
#mybet-list-success::-webkit-scrollbar {
    width: 8px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
#mybet-list-success::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e4e4e4;
}
.dOptin{
	width: 92%;
    display: inline-block;
}
.am-at{
	display: contents!important;
    line-height: 30px;
}