.portlet {
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 0px;
}
.portlet.box {
    padding: 0px !important;
}
.portlet > .portlet-body.blue-hoki, .portlet.blue-hoki {
    background-color: #10b2a5;
}
.portlet > .portlet-title {
    min-height: 41px;
}
.portlet.box > .portlet-title {
    border-bottom: 0;
    padding: 0 10px;
    margin-bottom: 0;
    color: #fff;
}
.portlet > .portlet-title:before, .portlet > .portlet-title:after {
    content: " ";
    display: table;
}
.portlet.box > .portlet-title {
    border-bottom: 0;
    padding: 0 10px;
    margin-bottom: 0;
    color: #fff;
}
.portlet > .portlet-title > .caption {
    float: left;
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;
    padding: 10px 0;
}
.portlet.box > .portlet-title > .caption {
    padding: 11px 0 9px 0;
}
.portlet.box.blue-hoki > .portlet-title > .caption {
    color: #fff;
    font-weight: 400;
}
.portlet > .portlet-body {
    clear: both;
}
.portlet.box > .portlet-body {
    background-color: #fff;
}
.form .form-actions {
    padding: 20px 10px;
    margin: 0;
    background-color: #f5f5f5;
    border-top: 1px solid #e5e5e5;
}
.form .form-actions {
    border-top: 1px solid #96c0ce;
}
.portlet.box.blue-hoki {
    border: 1px solid #10b2a5;
}
.blue-hoki.btn {
    color: #FFFFFF;
    background-color: #10b2a5;
}
.lbl {
    font-weight: normal;
    font-size: 14;
}
.inlf {
    display: inline-flex;
}

.opt-ups label{
	font-size: 15px;
    line-height: 34px;
	padding-left: 30px;
}
.opt-ups input {
    width: 19px;
    height: 19px;
    margin-top: 6px!important;
	
}
.opt-ups input[type=radio]{
	    margin-left: -30px;
}
input[type=file]{
	margin-top:6px;
	margin-bottom:6px;
	width: 96%;
    border-radius: 5px;
}

.select-sport {
    width: 100%!important;
    float: right;
    margin-right: 5px;
    border-radius: 10px;
}