
.modal-content{
	border-radius: 10px;
}
.modal-detail-order-title{
	text-transform: uppercase;
}
.rm10{
	margin:5 0 0;
}
.pop-sale-order{
	line-height:34px;
}
.pad0{
	padding:0;
}
.btYes,.btNo{
	width:70px;
}
.modal .modal-dialog .modal-content{
	max-width:300px;
	margin:auto;
}

button.close {    
    color: #333!important;
    opacity: 1!important;
}
#time-scd .input-group {
    width: 100%;
}
.table-condensed td,.table-condensed th {
    text-align: center;
}
#date-scd .react-datepicker-popper {
    z-index: 3;width:100%;
}
#date-scd .react-datepicker {
    width: 60%;
}
.rowct i{
	line-height:34px;
}
.referredMail, .referredTel, .isPreferredSMS{
	padding:6px 3px!important;
}
.mg5{
	margin-top:5px;
}
.rowct .form-group{
	    margin: 0 15px;
}
.pdr0{
	padding-right:0;
}
.rowct .input-icon-ofCus {
    border-left: 1px solid #ccc!important;
}
#time-scd .table-condensed>tbody>tr>td, #time-scd .table-condensed>tbody>tr>th{
	padding:1px!important;
	text-align: center!important;
}
#time-scd .dropdown-menu:before {
    position: absolute;
    top: -8px;
    right: 59px;
    display: inline-block !important;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e0e0e0;
    border-left: 8px solid transparent;
    content: '';
}
#time-scd  .dropdown-menu{
	right: 50px!important;
    min-width: 140px;
    width: 150px !important;
	font-size:13px!important;
}
#time-scd .dropdown-menu:after {
    position: absolute;
    top: -7px;
    right: 60px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #fff;
    border-left: 7px solid transparent;
    content: '';
}
.div0{
	padding:0;
}
.bt-closerd{
	color: #fff!important;
    background-color: rgb(184, 25, 0)!important;
	border-color: #826c68!important;
}
.modal{ display:block!important;
 }
.modal-title{
	text-transform: uppercase;
}

.btn-primary{
	color: #fff;
    background-color: #04978b!important;
    border-color: #04978b!important;
    text-transform: capitalize!important;
}
.btn-primary:hover,.btn-primary:active{
	color: #04978b!important;
    background-color: #ffffff!important;
    border-color: #04978b!important;
    text-transform: capitalize!important;
}
#modal-likes .modal-content {
    max-width: inherit;
    width: 445px;
    height: 490px;
}
.title-stage{
	color: #a90707;
}

#modal-ad .modal-dialog .modal-content {
    max-width: inherit;
}
#btn-start {
    width: 149px;
    height: 49px;
    border-radius: 4px;
    background-color: #04978b!important;
    border-color: #ddd!important;
    font-size: 16px;
    line-height: 27px;
    color: #fff!important;
    font-family: Quicksand;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase!important;
}
.btn-back {
    font-size: 12px;
    line-height: 27px;
    color: #3b8bc1a8!important;
    font-family: Montserrat;
    margin-right: 15px;
}
#modal-ad .form-control{
	/*height:40px;*/
}
#img-logo .avatar-container figure {
    float: left;
    margin: 10px;
    margin-bottom: 0px;
	margin-left:0px;
    height: 45px;
    width: 110px;
    border-radius: 0;
    background-color: #dadada;
    text-align: center;
    line-height: 0;
}
#img-logo .avatar1 {
    border-radius: 0% !important;
    height: 45px!important;
    width: 110px!important;
    padding: 0;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
	border: 1px solid #ddd;
}
#img-logo #process-container {
    left: 0;
}
#img-logo .ctnavatar-container{
	padding: 5px 25px;
}
#modal-ad .modal-dialog .modal-content {
    max-width: 450px;
}
#modal-ad input{
	border-radius:4px;
}
#modal-ad .modal-body {
    position: relative;
    padding: 5px 15px;
}
#modal-ad .form-group {
    margin-bottom: 7px;
}
.btn-delete, .btn-delete:hover {
    color: #752326;
	float:left;
	line-height: 45px;
}
#modal-ad  .modal-header {
    padding: 5px 15px;
}
#modal-ad  .title-stage {
    color: #a90707;
    font-size: 16px;
}
.language-ad{
	font-size: 11px;
    color: #9E9E9E;
    font-weight: 300;
    font-style: italic;
}
.mgt0{
	margin-top:0px!important;
}
#modal-ad .modal-header .close {
    margin-top: 5px;
}
#modal-ad .modal-footer {
    padding: 5px 15px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}
#modal-ad #btn-start {
    width: 80px;
    height: inherit;
    border-radius: 4px;
    background-color: #04978b!important;
    border-color: #ddd!important;
    font-size: 14px;
    line-height: inherit;
    color: #fff!important;
    font-family: Quicksand;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase!important;
}
#modal-ad .btn-delete,#modal-ad .btn-delete:hover {
    color: #752326;
    float: left;
    line-height: inherit;
    padding-top: 8px;
}
/*----- Center-----*/
.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
/*-----End Center-----*/
#modal-block .modal-content {
    max-width: inherit;
    width: 410px;
    height: 490px;
}
#modal-block .modal-header {
    padding: 10px 15px;
    border-bottom: 1px solid #e5e5e5;
}
#modal-block .modal-body {
    position: relative;
    padding: 10px 15px;
}
#modal-block .bd-bt{
	    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
}
#modal-block-confirm .modal-content {
    width: 460px;
	max-width: inherit;
}
#modal-block-confirm .modal-title-cap{
	color: rgb(184, 25, 0);
	text-transform:none;
}
#modal-block-confirm .fa-warning{
	font-size: 38px;
    color: #f3e107;
}
#modal-block-confirm .cnt-block{
	margin-top: 5px;
    margin-left: 10px;
}
#modal-edit_descr .modal-content {
    max-width: 100%;
    margin: auto;
}
.textarea-edit{
	min-height:100px;
	border:0px;
	width:100%;
	padding:10px;
	outline-color:none;
}
.txt-note{
	text-align: right;
    float: right;
    color: #aeaeae;
}
#modal-uploadInvoice .modal-content {
    max-width: inherit;
    width: 530px;
}
.btn-closed{
	color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 49%;
    font-size: 15px;
}
.btn-send{
	background-color: #9b59b6;
    width: 49%;
    font-size: 15px;
}
.fileContainer{
	padding:5px 0!important;
}
.txt-rpt{
	font-size:14px;
	font-weight:400;
	display: block;
}
#modal-viewReport .modal-content {
    max-width: inherit;
    width: 700px;
}
.lig-box-img{
	z-index:1051!important;
}