.list-user .items {
    margin-top: 25px;
}
.list-user .items .left h3.header {
    text-align: center;
    text-transform: uppercase;
    /*padding: 15px 0;*/
    background-color: #04978b;
    margin: 0px;
    color: white;
    border-radius: 5px 5px 0 0;
}

h3 {
    font-size: 1.2em;
}
.list-user .items .left .content {
    float: left;
    width: 100%;
    border: solid 1px #cecece;
    border-top: none;
}
.list-user .items .left .ctn figure {
    float: left;
    margin: 10px;
    margin-bottom: 0px;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: #dadada;
    text-align: center;
    line-height: 73px;
}
.list-user .items .left .ctn .tipster span.lbl {
    width: 100%;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 5px;
}
.list-user .ctn .tipster span.ctn {
    background: url('https://www.coopbet.com/media/icontipsterctn.png') no-repeat;
    padding-left: 24px;
}
.list-user .items .left .sport-tip {
    text-align: center;
    text-transform: uppercase;
    background-color: #ececec;
    float: left;
    width: 100%;
    padding: 5px 0;
    margin: 14px 0;
	margin: 14px 0 0;
}
.list-user .items .left .sport-tip span {
    /*background: url('https://www.coopbet.com/media/iconsoccerball.png') no-repeat 0 1px;*/
    padding-left: 7px;
    line-height: 27px;
    display: inline-block;
    color: #04978b;
    vertical-align: bottom;
}
.list-user .profit {
    float: left;
    width: 100%;
}
.list-user .items .left .profit li {
    float: left;
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
}
.list-user .profit li b {
    float: left;
    width: 50%;
    text-align: right;
}
.list-user .profit li span {
    float: left;
    margin-left: 10px;
}
.list-user .items .left .footer {
    text-align: center;
    float: left;
    width: 100%;
    padding: 8px 0;
    margin: 9px 0;
}
.list-user .items .left .footer .add-friend {
    border: solid 1px #cccccc;
    border-radius: 5px;
    padding: 8px 10px 8px 32px;
    color: black;
    background: #ececec url('https://www.coopbet.com/media/iconaddfriend.png') no-repeat 5px 6px;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
}
.list-user .items .left .footer .add-friended{
    border: solid 1px #cccccc;
    border-radius: 5px;
    padding: 8px 10px 8px 10px;
    color: black;
	 background: #ececec ;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
}
.list-user .items .left .footer .following {
    border: solid 1px #05978b;
    padding: 8px 15px;
    color: black;
    /* float: right; 
    margin-left: 5px;*/
    border-radius: 5px;
    cursor: pointer;
    color: #05978b;
}
.list-user .items .right .header {
    text-align: center;
    /*padding: 14px 0;*/
    background-color: #e4e4e4;
    float: left;
    width: 100%;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
}
.list-user .items .right .header > div.w-0, .list-user .items .right .ctn div.w-0 {
    width: 100px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
	height: 1px;
}
.list-user .items .right .header > div.w-1, .list-user .items .right .ctn div.w-1 {
    width: 70px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
}
.list-user .items .right .header > div.w-2 {
    width: 230px;
    min-height: 20px;
    float: left;
    text-align: left;
    font-size: 12px;
}
.list-user .items .right .header > div.w-3, .list-user .items .right .ctn div.w-3 {
    width: 140px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
}
.list-user .items .right .ctn {
    float: left;
    width: 100%;
    border: solid 1px #dadada;
    border-top: none;
}
.list-user .items .right .ctn .sitem {
    float: left;
    width: 100%;
    border-bottom: solid 1px #dddddd;
    padding: 5px 0;
}
.list-user .items .right .header > div.w-0, .list-user .items .right .ctn div.w-0 {
    width: 100px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
}
.list-user .items .right .header > div.w-1, .list-user .items .right .ctn div.w-1 {
    width: 70px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
}
.list-user .items .right .ctn div.w-2 {
    width: 230px;
    min-height: 33px;
    float: left;
    text-align: left;
    font-size: 12px;
    /*overflow: hidden;*/
}
.list-user .items .right .header > div.w-3, .list-user .items .right .ctn div.w-3 {
    width: 140px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
}
span.won {
    background-color: #e20000;
}

span.status {
    color: white;
    border-radius: 10px;
    border-radius: 6px;
    display: inline-block;
    padding: 3px 10px 1px;
    text-transform: uppercase;
    font-weight: 700;
    width: 60px;
    width: auto;
}
.like a.like-bet, .like span.like-bet {
    background: url('https://www.coopbet.com/media/likeicon.png') no-repeat top 0px left 15px;
    float: left;
    height: 17px;
    font-size: 13px;
    line-height: 27px;
    text-align: left;
    padding-left: 39px;
    cursor: pointer;
}
.like span {
    margin-top: 5px;
    float: left;
}
.list-user .comment {
    margin: 5px;
    width: 99%;
}
.list-user .comment .content {
    overflow: hidden;
}
.list-user .comment a.action {
    cursor: pointer;
    color: #04978b;
}
.list-user .items .right .ctn .sitem {
    float: left;
    width: 100%;
    border-bottom: solid 1px #dddddd;
    padding: 5px 0;
}
.list-user .comment .content span {
    font-size:13px!important;
	line-height: 1.4!important;
}
.list-user .comment a.action {
    cursor: pointer;
    color: #04978b;
	font-size:13px!important;
}
.list-user .items .r-footer {
    float: left;
    width: 100%;
    border-bottom: dotted 1px #0f3234;
    padding: 5px 0;
}
.list-user .items .r-footer .f-left {
    float: left;
}
.list-user .items .r-footer .f-left .icon-1 {
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url('https://www.coopbet.com/media/icon1.png') no-repeat;
}
.list-user .items .r-footer .f-left .icon-2 {
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url('https://www.coopbet.com/media/icon2.png') no-repeat;
}
.list-user .items .r-footer .f-right {
    float: right;
    color: #797979;
    padding-top: 10px;
}
.list-user .items .r-footer .f-right .lbl {
    font-weight: 700;
    font-size: .9em;
}
.list-user .items .r-footer .f-right .ctn {
    text-transform: uppercase;
}
.list-user .ctn img {
    border-radius: 50%;
}
img {
    vertical-align: middle;
}
span.draw {
    background-color: #04988b;
}
span.status {
    color: white;
    border-radius: 10px;
    display: inline-block;
    padding: 3px 10px 1px;
    text-transform: uppercase;
    font-weight: 700;
    width: 60px;
    width: auto;
}
.list-user .items .img-adv {
    border: solid 1px #dddddd;
    text-align: center;
    padding: 10px;
    height: 73px;
    margin-bottom: 10px;
    border-radius: 5px;
}
.list-user .items .desc-adv {
    border: solid 1px #dddddd;
    height: 73px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}
span.pending {
    background-color: #797979;
}
span.lost {
    background-color: #0f3234;
}
.like a.like-bet.active {
    background: url('https://www.coopbet.com/media/likeactiveicon.png') no-repeat top 0px left 15px;
}
.__react_component_tooltip.type-dark {
    color: #fff;
    background-color: #04978b!important;
    border-radius: 5px;
}
.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #04978b!important;
    border-top-style: solid;
    border-top-width: 6px;
}
.img-icon{
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-height: var(--yt-img-max-height, none);
	max-width: var(--yt-img-max-width, 100%);
	border-radius: var(--yt-img-border-radius, none);
}
.pl0{
	padding-left:0px;
}
.mg0{
	margin:0px;
}
.pd10_0{
	padding:10px 0px;
}
.border-bt{
	border-bottom: 1px solid #ddd;
	margin-bottom:10px;
	margin-top:10px;
}
.name-comment{
	font-size:14px!important;
	color:rgb(10, 10, 10)!important;
	/*font-family:Roboto, Arial, sans-serif!important;*/
	font-weight:500!important;
	text-decoration-color: rgb(10, 10, 10);
	text-decoration-line: none;
	text-decoration-style: solid;
	margin-bottom:0px;
}
.date-comment{
	color: rgba(17, 17, 17, 0.6);
	font-size: 1.3rem;
	font-size: 13px;
	font-weight: 400;
	line-height: 1.8rem;
	text-transform: none;
}
.content-comment{
	padding-top:15px;
}
.list-user .items .left .footer .friends-list {
    padding: 0px 15px 0px 28px;
    color: #05978b;
    background: url(https://www.coopbet.com/media/friendslisticon.png) no-repeat 5px 9px;
    display: inline-block;
    border-radius: 5px;
    border: solid 1px #05978b;
}
.flwg{
	background: #04978b;
    border: 1px solid #04978b;
    color: white!important;
}
.userprofile{
	color:#fff;
	font-size:18px;
	font-size:16px;
}
.userprofile:hover{
	color:#fff;
}
.usertip{
	color:rgb(51, 51, 51);
}
.usertip:hover{
	color:rgb(51, 51, 51);
}
.running{
	font-size: 11px;
    border-radius: 10px;
    display: inline-block;
    padding: 3px 10px;
    background-color: #04978b;
    color: #fff;
}
.w-1 .status{
	font-size:10px;
	font-size:9px;
}
.running:hover{
	color: #fff;
}
.list-user .items .l-footer .f-right{
	display: inline-flex;
	
}
.list-user .items .r-footer{
	border-bottom: dotted 0px #0f3234;
}
.list-user .items .l-footer{
	border-bottom: dotted 1px #ddd;
	padding-left: 0px;
    padding-right: 0px;
}
.ctn-dt{
	padding-left:5px;
	font-style: italic;
}
.no-bd{
	border:0px!important;
}
.img-baner{
	border-radius: 0px!important;
}
.div-baner{
	    width: 100%;
    text-align: center;
}
ul.addB{
	float: left!important;
    margin-left: 110px;
    font-size: 12px;
    margin-top: 10px;
}
.img-baner{
	width:261px;
	height:160px;
	overflow: hidden;
    object-fit: cover;
}
.creat-ad{
	font-size: 15px;
    cursor: pointer;
    font-weight: 500;
}
.creat-ad:hover{
	text-decoration: underline!important; 
	color: cadetblue;
}
.logo-ad{
	width: 100px;
    height: 50px;
    object-fit: cover;
}
.txtcenter{
	text-align:center;
}
.caption-ad{
	font-weight:bold;
	margin-bottom: 0;
	margin-top:5px;
}
.lnk-note-ad{
	font-size:10px;
	color:#ccc;
}
.row-ad{
	padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    margin: 0;
}
.desc-ad{
	padding:8px 15px;
	height:100px;
}
.creat-by-ad {
	padding: 5px 15px;
    font-size: 11px;
    font-weight: 600;
}
#table-ad tbody td{
	border:1px solid #ddd;
}
.cell-vertical{
	vertical-align: inherit!important;
}
.note-to{
	float:right;
	text-align:right;
	font-size: 11px;
    color: #fd8607;
	padding: 5px 15px;
}
.brth-box{
	border: 1px solid #ccc;
    padding-top: 7px;
	width: 49.5%;
    margin-right: 0.5%;
    border-radius: 3px;
	margin-bottom:10px;
	/*height: 200px;*/
}
.icon-remove{
	font-size: 11px;
    border-radius: 10px;
    display: inline-block;
    padding: 3px 10px;
    background-color: #de484d;
    color: #fff;
	margin-top: 5px;
}
.list-user .items .left h3.header{
	line-height: inherit;
}
#table-ad thead td{
	text-transform: uppercase;
}
.pdl15{
	padding-left:15px;
}
.padlhr{
	padding-left:20px;
}
.heig50{
	height:50px!important;
}
a.like-bet {
   /*background: url(https://www.coopbet.com/media/like_n.png) no-repeat top 0px left 0px;*/
    float: left;
    height: 20px;
    font-size: 13px;
    line-height: 27px;
    text-align: left;
   /* padding-left: 30px;*/
    cursor: pointer;
    padding-right: 7px;
}
a.like-bet-active {
    /*background: url(https://www.coopbet.com/media/likedat.png) no-repeat top 1px left 0px;*/
    float: left;
    height: 20px;
    font-size: 13px;
    line-height: 27px;
    text-align: left;
    /*padding-left: 29px;*/
    cursor: pointer;
    padding-right: 7px;
    width: 22p;
    background-size: 20px 20px;
}
a.like-bet-active i {
    font-size: 16px;
    font-size: 22px;
    color: #04978b;
}
a.like-bet i {
    font-size: 16px;
    font-size: 22px;
}
.num-like {
    padding-left: 5px;
    color: #333;
}
.cmt-rgt{
	padding:0px;
	text-align:right;
}
.cmt-lft{
	padding:0px;
	text-align:left;
}
.icon-sport{
	font-size:30px;
	color: #04978b;
}
.chk-select-all .checkbox{
	margin-top:20px;
	margin-bottom:0px;
}
.fnt26px{
	font-size:25px!important;
}
.icon-running {
    font-size: 22px;
    color: #333;
}

.runing-ct{
	text-align:center;
}
.f-right.create-date{
	display:block!important;
	padding-right:0px;
	text-align:right;
	padding-top:5px!important;
}
.v-more{
	float: right;
    /*padding-top: 20px;*/
    color: blue;
}
.num-running{
	padding-left:5px;
}
.check-tip{
	font-size: 16px;
    color: #4fbf27;
}
.num-running{
	cursor:pointer;
}
.txt-ct{
	text-align:center;
}
.txt-str{
	font-size:15px;
	color:#999;
	margin-right:10px;
	line-height: 32px;
}
.img-icon-sm24{
	border: 1px solid #f5f5f5!important;
}
.lhi0{
	line-height:0px;
	line-height:30px!important;
}
.fnt-up{
	text-transform: uppercase;
}
.date-comment div{
	line-height:0px;
}
.bdbt1{
	border-bottom:1px solid #ddd;
}
.name-cmt div{
	line-height:0px!important;
}
.name-cmt .lhi0{
	line-height:30px!important;
}
.txt-cm-small{
	color:#ccc;
	font-weight:400;
}
#btn-create{
	cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
    border: solid 1px #dddddd;
    border-radius: 5px;
}
.div-load-more{
	padding:0;
	text-align:right;
}
#load-more {
    border-radius: 20px;
    background-color: white!important;
    border: 2px solid #008b8b;
    padding: 10px 15px;
    color: darkcyan;
}
#load-more:hover, #load-more:active,#load-more.focus, #load-more:focus {
    color: darkcyan!important;
	outline:none!important;
}
.not-sticker-mth{
	margin-top:-45px;
}
#all-list-home{
	height:auto;
}
.ct-ln-cado{
	text-align: center;
    background-color: #3e3e3f;
    color: #fff;
	font-size: 18px;
    font-weight: 500;
}
.lnk-cado:hover ,.lnk-cado:active,.lnk-cado.focus,
.lnk-cado.active.focus, .lnk-cado.active:focus, .lnk-cado.focus, .lnk-cado:active.focus, .lnk-cado:active:focus, .lnk-cado:focus  {
    color:yellow;
}
.ct-ln-kito{
    text-align: center;
    background-color: #3e3e3fd9;
    color: yellow;
	font-size: 20px;
    font-weight: 500;
}