/*@import url(http://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700&subset=all);*/
body {
    font-size: 13px;
	color: #333;
    font-family: Arial, Verdana, Helvetica, Sans-Serif !important;
    margin: 0px!important;
    padding: 0px!important;
}
#body {
    clear: both;
    padding-bottom: 35px;
    margin-top: -4px;
}
.list-user .ctn .tipster {
    float: left;
    width: 140px;
}
.comment {
    float: left;
    width: 100%;
}
body {
    margin: 0;
    font-family: Arial, Verdana, Helvetica, Sans-Serif;
    font-size: 13px!important;
    font-weight: 400;
    line-height: 1.4!important;
    color: #212529;
    text-align: left;
    background-color: #fff;
}
::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: white;
    -webkit-box-shadow: inset 0 0 6px white;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px white;
    -webkit-border-radius: 10px;
    border-radius: 10px;
	background-color: #d6d7d7cc;
}

#img-banner-mobile .avatar1 {
    border-radius: 0% !important;
    height: 50px!important;
    width: 150px!important; 
	height: 80px!important;
    width: 340px!important;
    padding: 0;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}
#img-banner-mobile  .avatar-container figure {
    float: left;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    height: 50px;
    width: 150px;
    border-radius: 0;
    background-color: #dadada;
    text-align: center;
    line-height: 0;
	height: 80px;
    width: 340px;
}
#img-banner-mobile #process-container {
    left: 0;
	    width: 340px!important;
    max-width: 340px;
}
.pdl0{
	padding-left:0px!important;
}

