#up-triangle {
    width: 0;
    height: 0;
    border-bottom: 140px solid #103252;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
}
 
#down-triangle {
    width: 0;
    height: 0;
    border-top: 140px solid #103252;
    border-left: 70px solid transparent;
    border-right: 70px solid transparent;
}
 
#left-triangle {
    width: 0;
    height: 0;
    border-right: 140px solid #103252;
    border-top: 70px solid transparent;
    border-bottom: 70px solid transparent;
}
 
#right-triangle {
    width: 0;
    height: 0;
    border-left: 140px solid #103252;
    border-top: 70px solid transparent;
    border-bottom: 70px solid transparent;
}

#triangle-top-right {
    width: 0;
    height: 0;
    border-top: 140px solid #103252;
    border-left: 140px solid transparent;
}
 
#triangle-top-left {
    width: 0;
    height: 0;
    border-top: 140px solid #103252;
    border-right: 140px solid transparent;
	border-top: 60px solid #ccf3c2;
    border-right: 60px solid transparent;
	cursor:pointer;
}
    
#triangle-bottom-right {
    width: 0;
    height: 0;
    border-bottom: 140px solid #103252;
    border-left: 140px solid transparent;
}
 
#triangle-bottom-left {
    width: 0;
    height: 0;
    border-bottom: 140px solid #103252;
    border-right: 140px solid transparent;
}
.triangle-text {
    color: #ad1414;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    right: -10px;
    top: -49px;
    white-space: nowrap;
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(0deg);
}
/***************************************/
.shape{    
	border-style: solid; border-width: 0 70px 40px 0; float:right; height: 0px; width: 0px;
	-ms-transform:rotate(360deg); /* IE 9 */
	-o-transform: rotate(360deg);  /* Opera 10.5 */
	-webkit-transform:rotate(360deg); /* Safari and Chrome */
	transform:rotate(360deg);
}
.offer{
	background:#fff; border:1px solid #ddd; box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); margin: 15px 0; overflow:hidden;
}
.offer:hover {
    -webkit-transform: scale(1.1); 
    -moz-transform: scale(1.1); 
    -ms-transform: scale(1.1); 
    -o-transform: scale(1.1); 
    transform:rotate scale(1.1); 
    -webkit-transition: all 0.4s ease-in-out; 
-moz-transition: all 0.4s ease-in-out; 
-o-transition: all 0.4s ease-in-out;
transition: all 0.4s ease-in-out;
    }
.shape {
	border-color: rgba(255,255,255,0) #d9534f rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-radius{
	border-radius:7px;
}
.offer-danger {	border-color: #d9534f; }
.offer-danger .shape{
	border-color: transparent #d9534f transparent transparent;
}
.offer-success {	border-color: #5cb85c; }
.offer-success .shape{
	border-color: transparent #5cb85c transparent transparent;
}
.offer-default {	border-color: #999999; }
.offer-default .shape{
	border-color: transparent #999999 transparent transparent;
}
.offer-primary {	border-color: #428bca; }
.offer-primary .shape{
	border-color: transparent #428bca transparent transparent;
}
.offer-info {	border-color: #5bc0de; }
.offer-info .shape{
	border-color: transparent #5bc0de transparent transparent;
}
.offer-warning {	border-color: #f0ad4e; }
.offer-warning .shape{
	border-color: transparent #f0ad4e transparent transparent;
}

.shape-text{
	color:#fff; font-size:12px; font-weight:bold; position:relative; right:-40px; top:2px; white-space: nowrap;
	-ms-transform:rotate(30deg); /* IE 9 */
	-o-transform: rotate(360deg);  /* Opera 10.5 */
	-webkit-transform:rotate(30deg); /* Safari and Chrome */
	transform:rotate(30deg);
}	
.offer-content{
	padding:0 20px 10px;
}