
body {
    font-size: 13px;
	color: #333;
    font-family: Arial, Verdana, Helvetica, Sans-Serif;
    margin: 0px!important;
    padding: 0px!important;
}
#header{
	background-color: #1f595d!important;
}
.menu-top-fix {
    background-color: #1f595d;
    float: left;
    width: 100%;
   /* position: fixed;*/
    top: 0px;
    z-index: 99;
    padding-top: 5px;
	/*display: inline-block;*/
}
.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.menu-top-fix .item {
    float: right;
    margin: 0 5px;
}
.pull-right {
    float: right !important;
}
.language {
    background-color: #003333;
    border-radius: 5px;
    padding: 2px 5px;
    margin-top: 8px;
}
.language select {
    border: none;
    background-color: #003333;
    color: white;
}
.menu-top-fix .line {
    float: right;
    color: white;
    height: 16px;
    border-left: solid 1px white;
    margin: 11px 3px 0;
}
.top-friend {
    position: relative;
    z-index: 99;
}
.menu-top-fix .top-friend {
    margin-top: 5px !important;
}
.menu-top-fix .item {
    float: right;
    margin: 0 5px;
}
.menu-top-fix .top-friend span.friend-icon {
    background: url('https://www.coopbet.com/media/socialicons.png') no-repeat;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0 -113px;
    opacity: .6;
    background-clip: content-box;
    background-origin: content-box;
    height: 24px;
    padding: 0 3px 3px 0;
    display: inline-block;
    cursor: pointer;
    width: 24px;
}
.login-top {
    position: relative;
    z-index: 99;
}
.login-partial {
    color: white !important;
    float: right;
    margin-top: 10px;
    position: relative;
    z-index: 99;
    min-width: 58px;
    height: 17px;
    overflow: hidden;
}

.login-partial li {
    float: left;
    float: right;
    margin-right: 5px;
    margin-right: 0;
}

ul li {
    list-style: none;
}
a, :hover {
    text-decoration: none !important;
}
a,select {    
    outline: none;
}
.login {
    width: 100%;
    padding-bottom: 15px;
}
.login-partial a.login {
    background: url('https://www.coopbet.com/media/loginicon.png') no-repeat top right;
	font-size: 13px;
}
.login-partial a {
    color: white;
    float: left;
	margin-right: 20px;
}
ul {
    margin: 0;
    padding: 0;
}
.menu-top-fix .item a.shopping-cart, .menu-top-fix .item a.msg {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    display: inline-block;
    height: 21px;
    width: 21px;
    position: relative;
    margin-top: 5px;
}
.menu-top-fix .item a.msg:before {
    /*background: url('https://www.coopbet.com/media/msgicon.png') no-repeat center;*/
}
.menu-top-fix .item a.shopping-cart:before, .menu-top-fix .item a.msg:before {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    content: '';
    width: 16px;
    height: 16px;
}
.menu-top-fix .item a.shopping-cart:before {
    background: url('https://www.coopbet.com/media/carticon.png') no-repeat;
}
.msg:hover{
	color:#333;
}
.menu-top-fix .item a.link {
    color: white;
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    display: inline-block;
    line-height: 30px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: 6px;
}
.bg-search {
    background-color: #0f3335;
}
.bg-search .left {
    float: left;
    /*width: 423px;*/
    width: 400px;
    /*margin-left: 353px;*/
    margin-left: 382px;
}
form {
    display: block;
    margin-top: 0em;
}
.bg-search .left form {
    position: relative;
}
input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input, textarea {
    border: 1px solid #e2e2e2;
    background: #fff;
    color: #333;
    font-size: 1.2em;
    margin: 5px 0 6px 0;
    padding: 5px;
}
.bg-search .left input.txt-search {
    background-color: white;
    border: solid 1px #0a7b7c;
    height: 25px;
    border-radius: 5px;
    color: black;
    font-size: 14px;
    width: 100%;
    margin: 4px 0 0 0;
    position: relative;
    padding: 0 5px 0 5px;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font: inherit;
    color: inherit;
}
button {
    overflow: visible;
}
button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
input[type="submit"], input[type="button"], button, a.button {
    border: solid 1px #dddddd;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
}
.bg-search .left .bt-search {
    background: #dddddd url('https://www.coopbet.com/media/searchicon.png') no-repeat center;
    background: #dddddd url('https://www.coopbet.com/media/search-icon.png') no-repeat center;
    position: absolute;
    top: 4.5px;
    right: 0px;
    width: 41px;
    height: 23px;
	border-radius:5px;
}
.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical > .btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-header:after, .modal-footer:after {
    clear: both;
}

.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after, .nav:before, .nav:after, .navbar:before, .navbar:after, .navbar-header:before, .navbar-header:after, .navbar-collapse:before, .navbar-collapse:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-header:before, .modal-header:after, .modal-footer:before, .modal-footer:after {
    display: table;
    content: " ";
}
.menu-top {
    background-color: #1f595d;
    float: left;
    width: 100%;
    /*position: fixed;*/
    top: 38px;
    z-index: 99;
   /* z-index: 98;*/
}
.menu-top .logo {
    float: left;
    margin: -30px 0 7px 0;
    padding-bottom: 8px;
}
.menu-setting {
    float: right;
    margin-top: 12px;
}
ul {
    margin: 0;
    padding: 0;
}
.menu-top ul.menu {
    /*float: right;*/
    float: left;
    margin-right: 30px;
    font-size: 12px;
    margin-top: 10px;
}
a, :hover {
    text-decoration: none !important;
}
a {
    color: #333;
    outline: none;
    text-decoration: none;
}
img {
    border: 0;
	vertical-align: middle;
}
.menu-setting .setting {
    float: right;
    position: relative;
    width: 30px;
    height: 30px;
    background: url('https://www.coopbet.com/media/settingicon.png') no-repeat top right;
    cursor: pointer;
}

.menu-setting ul.s-menu {
    display: none;
    width: 260px;
    position: absolute;
    top: 30px;
    right: 0;
    font-size: 11.5px;
    background-color: #0f3b3e;
}
ul li {
    list-style: none;
}
.menu-setting ul.s-menu > li a {
    padding: 5px 0 5px 26px;
}
.menu-setting ul.s-menu a {
    color: white;
    text-transform: uppercase;
    border-top: solid 1px #19494c;
    border-bottom: solid 1px #35767b;
    display: inline-block;
    width: 100%;
}
.menu-setting ul.s-menu .ss-menu a {
    background-color: #1f595d;
    padding: 5px 0 5px 46px;
}
.menu-setting .add-tips {
    float: right;
    margin-right: 5px;
}
.menu-top ul.menu > li {
    float: right;
    position: relative;
    list-style: none;
    margin-left: 44px;
}
.menu-top ul.menu li a {
    text-transform: uppercase;
    color: #00FFFF;
}
.menu-top ul.menu > li a {
    border-top: solid 3px #1f595d;
    display: inline-block;
    padding-top: 7px;
}
.menu-setting .add-tips a {
    display: inline-block;
    padding: 5px 10px;
    border: solid 1px #00fcfd;
    border-radius: 10px;
    color: #00fcfd;
    text-transform: uppercase;
    font-size: 11px;
}
.menu-top ul.menu > li a:hover, .menu-top ul.menu > li a.active {
    border-top: solid 3px white;
    color: white;
}
header, footer, hgroup, nav, section {
    display: block;
}
header {
    background: black url('https://www.coopbet.com/media/bgtop.jpg') no-repeat center;
    float: left;
    width: 100%;
    height: 191px;
    margin: 76px 0 0 0;
}
header .header-1 {
    float: left;
    border-right: solid 6px #01f6f6;
    margin-top: 62px;
    padding: 5px 25px 5px 0;
    margin-right: 25px;
}
header .header-1 .t-1 {
    color: white;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 700;
    display: block;
}
header .header-1 .t-2 {
    color: white;
    font-size: 20px;
    display: block;
}
header .header-2 {
    float: left;
    margin-top: 62px;
    color: white;
    width: 40%;
}
header .header-2 > div span.lbl {
    text-transform: uppercase;
    float: left;
    width: 15%;
    height: 54px;
    margin-right: 35px;
    font-weight: 700;
}
header .header-2 > div span.ctn {
    display: block;
    position: relative;
    margin-bottom: 10px;
    font-size: 14px;
    float: left;
    width: 70%;
}
header .header-2 > div span.ctn:before {
    width: 5px;
    height: 8px;
    content: '';
    top: 6px;
    left: -15px;
    position: absolute;
    background: url('https://www.coopbet.com/media/listicon.png') no-repeat top left;
}
header .header-2 .we {
    display: flow-root;
	
}


footer {
    padding: 20px;
    background-color: #0f3335;
    border-top: solid 5px #1f595d;
}
h1, h2, h3, h4, h5, h6 {
    padding-bottom: 0;
}
footer h4.header {
    text-transform: uppercase;
    color: white;
    font-size: 17px;
    margin: 15px 0;
}
footer .content {
    color: #a0b7b8;
}
.links a {
    color: white;
    background: url('https://www.coopbet.com/media/icon_info.png') no-repeat 0 3px;
    padding-left: 10px;
}
footer h4.header {
    text-transform: uppercase;
    color: white;
    font-size: 17px;
    margin: 15px 0;
}
footer .content .item {
    float: left;
    margin-right: 10px;
}
footer .bottom {
    border-top: solid 1px #2c4c4e;
    text-align: center;
    text-transform: uppercase;
    margin-top: 25px;
    padding-top: 25px;
    color: white;
}
footer h4.header a {
    color: white;
}
footer .content.follow-us a:hover {
    opacity: .8;
}

footer .content.follow-us a {
    width: 39px;
    height: 39px;
    background-color: #0f3e7d;
    color: white;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    display: inline-block;
    line-height: 37px;
    border-radius: 50%;
}
.setting:hover ul.s-menu {
    display: block;
}
.box-login {
    position: absolute;
    padding-top: 7px;
    top: 25px;
    /* top: 75px; 
    right: 30%;*/
    right: -20%;
    width: 250px;
    border-radius: 5px;
    display: none;
    padding-bottom: 10px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.box-login h1 {
    margin: 0px;
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    border-radius: 5px 5px 0 0;
    background-color: #04978b;
    font-weight: 700;
    padding: 17px 0;
    color: white;
    position: relative;
}
.box-login .content {
    padding: 10px;
    background-color: white;
	/*border: solid 1px #1f595d;*/
}
.box-login .content .btn-sm {
    text-align: center !important;
    width: 100%;
    padding: 7px 0px;
    font-size: 12px!important;
    border-radius: 5px!important;
}
.externall-login .item-ex {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
}
input[type="submit"], input[type="button"], button, a.button {
    border: solid 1px #dddddd;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px 15px;
    background-color: #04978b;
}
.externall-login .item-ex a {
    color: white;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    display: inline-block;
}
.externall-login .item-ex a.login-facebook {
    background: #3e5c98;color: white;
}
.externall-login .item-ex a .fa {
    display: inline-block;
    border-right: solid 1px black;
    padding: 5px 0;
    width: 30px;
    text-align: center;
    margin-right: 10px;
}
.externall-login .item-ex a.login-google {
    background: #e04a39;color: white;
}
.btn-sm, .btn-group-sm > .btn {
    font-size: 12px!important;
    line-height: 1.5!important;
    border-radius: 3px!important;
}
#btn_login:hover,.login-facebook:hover,.login-google:hover,.login-partial a:hover{
	color: yellow!important;opacity: .8;
}
.box-login .new-account {
    width: 100%;
    text-align: center;
}
.box-login .new-account span.lbl {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
}
.box-login .new-account a {
    background-color: #027b5b;
    color: white;
    width: 100%;
    padding: 7px 0;
    border-radius: 5px;
    font-size: 12px;
    display: inline-block;
    text-transform: uppercase;
	cursor:pointer;
}
.logo{
	width: 20%;
    display: inline-block;
}
.box-login h1:before {
    top: -6px;
    border-style: solid;
    border-color: transparent transparent #04978b;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    right: 15px;
}
.box-login .content .input-group {
    margin-bottom: 10px;
}
.box-login .content .checkbox span {
    display: inline-block;
    padding-left: 20px;
    vertical-align: middle;
}
.box-login .content #signin_remember {
    margin-left: 0px!important;
}
.forgot-password {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    color: #2e2b28;
    text-align: center;
    margin: 10px 0;
    display: block;
}
.box-login .content .checkbox{
	margin-top:0px; 
    margin-bottom:0px; 
}
.externall-login{
	margin-top:-10px;
}
.head-page{
	position: fixed;z-index:1000;
}
.login-partial a.login .fa{
	position: fixed;
    font-size: 16px;
    top: 15px;
    right: 270px;
}
.kep-login-facebook {
    font-family: Helvetica,sans-serif!important;
    font-weight: 400!important;
    -webkit-font-smoothing: antialiased!important;
    color: #fff;
    cursor: pointer;
    /* display: inline-block; */
    font-size: 13px!important;
    text-decoration: none!important;
    text-transform: inherit!important;
    transition: background-color .3s,border-color .3s!important;
    background-color: #3e5c98!important;
    border: none!important;
    padding: 0px!important;
	outline: none!important;
}
.login-google button {
    display: inline-block!important;
    background: rgb(224, 74, 57)!important;
    color: rgb(255, 255, 255)!important;
    width: 190px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    border: none;
    font-size: 14px!important;
    font-weight: 400!important;
    font-family: inherit!important;
    text-align: left;
    text-transform: inherit!important;
    padding: 0px!important;
	outline:none;
}
.login-partial .user{
	margin:0px;
	max-width: 95px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.menu-top ul.menu-l{
	float: left;
    margin-left: 110px;
    font-size: 12px;
    margin-top: 10px;
	
}

.friend-icon-w {
    background-image: url(https://www.coopbet.com/media/DQxkij5MnZT.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 0 -542px;
    opacity: .6;
    background-clip: content-box;
    background-origin: content-box;
    height: 24px;
    padding: 0 3px 3px 0;
    display: inline-block;
    cursor: pointer;
    width: 24px;
	/*display:none;*/

}
.p-f-right{
	text-align:right;
	color: #9E9EA0;
}
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin: 0;
    margin-left: 5px;
    vertical-align: top;
}
.switch .slider{
    max-height: 360px;
    overflow: hidden;
    min-height: 27px;
    width: 50px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.switch input:checked+.slider {
    background-color: #00fcfd;
}
.switch .slider.round {
    border-radius: 34px;
}
.switch input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.switch .slider.round:before {
    border-radius: 50%;
}
.switch .slider:before {
    position: absolute;
    content: "";
    height: 23px;
    width: 23px;
    left: 1px;
    bottom: 2px;
    background-color: #1f595d;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.badge {
	font-family: "Open Sans", sans-serif;
    position: absolute;
    top: -3px;
    top: -6px;
    right: 10px;
    right: 12px;
    font-weight: 300;
    padding: 3px 6px;
    background-color: #f36a5a;
    color: #ffffff;
}
.noti-box:before {
    top: -8px;
    border-style: solid;
    border-color: transparent transparent #fff;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    right: 15px;
}
.noti-box{
	background-color:#fff;
	padding:0px;
	position: relative;
	border: 1px solid #ddd;
	
}
#__react_bs_dd_menuItems_1{
	background-color: rgba(0, 0, 0, 0)!important;
	padding: 12px 0px 0px 0px!important;
	left: -400px!important;
	min-width: 440px!important;
}
.glyphicon-bell{
	padding:0px!important;
	font-size: 20px!important;
	color: #fff!important;
}
.msg-nofi{
	text-align: center;
    display: inline-block;
    height: 21px;
    width: 21px;
    position: relative;
    margin-top: 5px;
}
.msg-nofi.scroller {
    min-height: 400px!important;
    height: inherit!important;
    overflow-y: auto!important;
    overflow-x: hidden;
    outline: none;
}
.rtool{
	text-align:right;
	line-height: 30px;
}
.bdnone{
	border:0px!important;
}
.brd1px{
	border-bottom: solid 1px #dddddd;
}
.name-user{
	font-weight:600;
	margin-right:5px;
}
.ct-user-nf{
	text-align:left;
	/*padding:10px 0;*/
	padding-top:7px;
}
.img-ntf{
	border-radius: 50%;
    border: 1px solid #f0f0f0;
}
.time-ntf{
	padding-left: 7px;
    color: #b3b3b3;
}
.ic-ntf{
	color: #1f595d;
}
#notifi-content .scroll-nt{
	min-height: 50px!important;
    height: inherit!important;
    max-height: 480px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.scroll-nt::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(27,25,25,.84);
    border-radius: 10px;
}
.scroll-nt::-webkit-scrollbar-thumb {
   background-color: #fff;
    background-image: -webkit-gradient(linear,40% 0,75% 84%,from(#004e40),color-stop(.6,#004c3f),to(#004c3f));
    border-radius: 10px;
}
.scroll-nt::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
}
#notifi-content .footer a{
	font-weight: 600;
    color: #1f595d;
	font-size:12px;
}
#notifi-content .header{
	height: 30px;
    line-height: 30px;
	font-size:12px;
}
#notifi-content .footer{
	line-height:30px;
}
.rtool a{
	font-size: 12px;
    font-weight: 400;
	color: #337ab7;
}
#notifi-content .ft-items .ctn .ft-item{
	padding: 5px 0;
}
.row-ntf{
	cursor:pointer;
}
.overView-term{
	max-width:100%;
}
.overView-term .f-ter{
	text-align:center;
}
.overView-term .body-ct {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 10px;
    padding-bottom: 10px;
}
.overView-term .btn {
    margin-bottom: 10px;
}
.popover-content p{
	margin:10px 0px;
}
.popover-content h3{
	margin:15px 0px;
}
.popover-content .tip-list {
  margin: 0;
  padding: 0 0 0 15px;
}

.popover-content .tip-list li {
  margin: 5px 0;
  padding: 0;
  list-style: disc;
}
.popover-content .tip-heading {
  margin: 0 15px;
}
.scroll-body{
	min-height: 50px!important;
    height: inherit!important;
    max-height: 450px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
}
.scroll-body p a{
	color: #337ab7;
}
.cl-red{
	color:#f36a5a;
}
.bd-p{
	border:1px solid;
	padding:10px;
}
.page-404{
	padding: 20% 0;
    text-align: center;
}
.ct-nf{
	margin:15px 0px!important;
}
.ic-nf{
	font-size: 25px!important;
    color: #a94442;
}
#profile-block .note {
    font-size: 12px;
    color: #9e9e9e;
}
#profile-block .mgt20{
	margin-top:20px;
}
#profile-block .txr-r{
	text-align:right;line-height:34px;
}
#profile-block .bt-block{
	padding: 7px 12px!important;
    font-size: 13px;
}
#profile-block .icon-bl{
	margin-right:5px;
	font-size:9px;
}
#profile-block .lb-name{
	font-weight:600;
	width:60%;
	cursor: pointer;
}
.a-block{
	color:blue;
}
.input-contact{
	border: 0;
    border-bottom: 1px solid #aeaeae;
    border-radius: 0;
    box-shadow: none!important;
	padding-bottom: 0;
}
.lbl-contact{
	line-height:40px;
}
.v-more {
    float: right;
    color: #00f;
}
.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 400px !important;
}
.rdw-editor-wrapper {
    box-sizing: content-box;
}
.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    user-select: none;
}
.rdw-inline-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-option-active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-wrapper {
    border: 1px solid #F1F1F1;
    padding: 5px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

.rdw-block-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-block-dropdown {
    width: 110px;
}
.rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
}
.rdw-dropdown-carettoopen {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.rdw-fontsize-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-fontsize-dropdown {
    min-width: 40px;
}
.rdw-dropdown-wrapper {
    height: 30px;
    background: white;
    cursor: pointer;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;
    background: white;
}
.rdw-fontfamily-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-fontfamily-dropdown {
    width: 115px;
}
.rdw-list-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-text-align-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-colorpicker-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}
.rdw-link-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}
.rdw-embedded-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}
.rdw-emoji-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}
.rdw-image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}
.rdw-remove-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
    flex-wrap: wrap;
}
.rdw-history-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    flex-wrap: wrap;
}
.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}
.demo-editor {
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}
.rdw-dropdown-optionwrapper:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
    background-color: #FFFFFF;
}
.rdw-dropdown-optionwrapper {
    z-index: 100;
    position: relative;
    border: 1px solid #F1F1F1;
    width: 98%;
    background: white;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
}
.rdw-embedded-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    justify-content: space-between;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-embedded-modal-header {
    font-size: 15px;
    display: flex;
}
.rdw-embedded-modal-link-section {
    display: flex;
    flex-direction: column;
}
.rdw-embedded-modal-btn-section {
    display: flex;
    justify-content: center;
}
.rdw-embedded-modal-link-input-wrapper {
    display: flex;
    align-items: center;
}
.rdw-embedded-modal-link-input {
    width: 88%;
    height: 35px;
    margin: 10px 0;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
.rdw-image-mandatory-sign {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}
.rdw-embedded-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}

.rdw-embedded-modal-size-input {
    width: 78%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
.rdw-image-mandatory-sign {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}
.rdw-embedded-modal-btn-section {
    display: flex;
    justify-content: center;
}
.rdw-embedded-modal-btn:disabled {
    background: #ece9e9;
}
button, html [type="button"], [type="reset"], [type="submit"] {
    -webkit-appearance: button;
}
.rdw-embedded-modal-btn {
    margin: 0 3px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
	color:#333!important;
}
.rdw-embedded-modal-size-input {
    width: 78%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
.rdw-embedded-modal-btn:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-emoji-modal {
    overflow: auto;
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 235px;
    height: 180px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-emoji-icon {
    margin: 2.5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rdw-image-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-image-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rdw-image-modal-header-label-highlighted {
    background: #6EB8D4;
    border-bottom: 2px solid #0a66b7;
}
.rdw-image-modal-header-label {
    width: 80px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
}
.rdw-image-modal-url-section {
    display: flex;
    align-items: center;
}
.rdw-image-modal-url-input {
    width: 90%;
    height: 35px;
    margin: 15px 0 12px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
.rdw-image-mandatory-sign {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}
.rdw-image-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}
.rdw-image-modal-size-input {
    width: 40%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
.rdw-image-mandatory-sign {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}
.rdw-image-modal-btn-section {
    margin: 10px auto 0;
}
.rdw-image-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-image-modal-btn {
    margin: 0 5px;
    width: 75px;
    height: 23px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
	color:#333;
	font-size:13px;
}
.rdw-image-modal-header-label-highlighted {
    background: #6EB8D4;
    border-bottom: 2px solid #0a66b7;
}
#input-date .react-datepicker-wrapper{
	width:80%;
}
#input-date .react-datepicker__input-container{
	width:100%;
}
#input-date .input-date{
	width:100%;
}
.note-to a{
	font-size: 13px;
    color: blue;
}
#contentbr {
    float: left;
    width: 100%;
    border: 1px solid #cecece;
    border-top: none;
}
h3.headerbr {
    text-align: center;
    text-transform: uppercase;
    background-color: #04978b;
    margin: 0;
    color: #fff;
    border-radius: 5px 5px 0 0;
	height: 36px;
}
.lef-br figure {
    float: left;
    margin: 10px 10px;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: #dadada;
    text-align: center;
    line-height: 73px;
}
.lef-br img {
    border-radius: 50%;
}
.lef-br .tipster {
    float: left;
    width: 140px;
}
.lef-br  .tipster span.lbl {
    width: 100%;
    text-transform: uppercase;
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 5px;
}
.lef-br .tipster span.ctn {
    background: url(https://www.coopbet.com/media/icontipsterctn.png) no-repeat;
    padding-left: 24px;
}
#tab-bankroll li>a{
	text-transform: uppercase;
}
#tab-bankroll .nav-tabs {
    border-bottom: 0px solid #ddd;
    background-color: #f6f6f6;
}
#tab-bankroll .tab-content{
	border:0px;
}
#tab-bankroll{
	margin-bottom:0;
}

#tab-bankroll .nav-tabs>li.active>a, #tab-bankroll .nav-tabs>li.active>a:focus,
#tab-bankroll .nav-tabs>li.active>a:hover {
    color: #c90a0a;
    cursor: default;
    background-color: #f6f6f6;
    border: 1px solid #f6f6f6;
    border-bottom-color: transparent;
}
#tab-bankroll .nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid #f6f6f6;
    border-radius: 0;
    background: #f6f6f6;
    color: #1f595d;
    font-weight: 600;
    border-right: 1px solid #ccc!important;
}
#tab-bankroll .nav-tabs>li {
    float: left;
    margin-bottom: 0;
}
.tbl-bank-rol>thead:first-child>tr:first-child>td{
	text-transform:uppercase;
	border: 1px solid #ddd;
    font-weight: 600;
}
.tbl-bank-rol .radio-inline input[type=radio]{
	margin:0px!important;
}
.tbl-bank-rol .radio-inline{
	vertical-align: inherit;
}
.tbl-bank-rol{
	border: 1px solid #ddd;
}
.title-br{
	text-transform:uppercase;
	margin-top:15px!important;
	font-weight:600;
}
.ad-bank.table>tbody>tr>td{
	border:1px solid #ddd;
}
.ad-bank.table{
	margin-bottom:15px;
}
.txt-rg{
	text-align:right;
}
.bnt-ad-bank{
	font-size:14px!important;
	background-color: #04978b!important;
	padding: 10px 25px !important;
}
#tab-bankroll .pagination{
	margin:10px 0px!important;
}
.rank-mth input{
	width: 115px!important;
    text-align: center;
	height: 32px;
    font-size: 14px;
}
.rank-mth .react-datepicker__month .react-datepicker__month-text, .rank-mth .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 50px;
    margin: 5px;
}
.rank-mth{
	padding: 10px 0;
    text-align: right;
}
.icon-caret{
	position: absolute;
    top: 17px;
    right: 5px;
}
.txt-f-t{
	text-align: center;
    border: 1px solid #ddd;
    padding: 10px 0;
    font-weight: 600;
    background-color: #f6f6f6;
    background-color: #eee;
    font-size: 15px
}
.tab-rank li>a {
    text-transform: capitalize!important;
}
.tab-rank .nav-tabs {
    background-color: #ddd!important;
}
#rank-tipter .tab-rank .nav-tabs>li>a{
	border: 0;
	background: #ddd!important;
	border-right: 1px solid #999!important;
	padding: 12px 60px;
}
#rank-tipter .tab-rank .nav-tabs>li.active>a, #rank-tipter .tab-rank .nav-tabs>li.active>a:focus, #rank-tipter .tab-rank .nav-tabs>li.active>a:hover {
   background-color: #ddd!important;
   padding: 12px 60px;
   border: 0;
   
}
.r-txt-search{
	margin:5px 0!important;
}
.search-tipter{
	height: 40px;
    border-radius: 0;
}
.btn-search-tip{
	text-transform: uppercase;
    width: 100%;
    border-radius: 0;
    line-height: 25px;
    background-color: #009864;
}
.ad-img-o-tip{
	margin:15px 0!important;
	margin:0!important;
	text-align:center;
	border: 1px solid #ddd;
    padding: 10px;
    height: 73px;
}
.tbl-tipters>thead>tr>td, .tbl-tipters>thead>tr>th{
	background-color: #eee;
    border: 0!important;
	padding: 12px 8px;
}
.tbl-tipters>tbody>tr>td{
	/*border-top: 0!important;
	border-bottom: 1px solid #ddd;*/
	    vertical-align: middle;
}
.tbl-tipters .img-tipster{
	width:50px;
	height:50px;
	border-radius:50%;
	border:1px solid #ddd;
}
.tbl-tipters .name-tip{
	font-size:14px;
}
.tbl-tipters .verifi-tip{
	font-size: 12px;
    color: #777;
}
.pd6_0{
	padding: 6px 0px!important;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #eeffee;
    border-color: #addead;
    color: #fe4064;
    font-weight: 900;
}
.pagination>li>a, .pagination>li>span {
    color: #1f595d;
}
.left-ft-admth{
	width: 70%;
    margin: 3px 0 0 8px;
    height: 34px;
    background-color: #83dbcc;
    border-radius: 4px;
    float: left;
	margin:3px 0 0 3px;
}
.ri-ft-admth{
	width: 28%;
    float: right;
}
#price_mth {
    width: 50px;
    height: 28px;
    vertical-align: top;
    margin: 3px;
    border-radius: 0;
    text-align: center;
    padding: 5px;
    display: inline-block;
	}
.unit-price{
		font-weight: 600;
	}
.sym1{
		padding: 0 6px;
		color: #fff;
		font-size: 16px;
	}
.left-ft-admth .fa-shopping-cart{
	color:#fff;
}
#btn-save-month-buy{
	width:68px;
	text-align:center;
	padding:6px;
}
.ct-ft-charge{
	width: 58%!important;
    margin: 7px 0 0 0;
	cursor:pointer;
}
.wd20{
	width: 20%;
    display: inline-block;
    float: left;
}
.footer-charge{
	margin: 0px;
    padding: 0px!important;
    margin-bottom: -10px!important;
}
.price-charge{
	font-weight: 600;
    color: #c24962;
	vertical-align: top;
}
.sym-charge{
	color: #fff;
    font-size: 16px;
    vertical-align: top;
    padding-left: 6px;
    padding-right: 6px;
}
.triangle-text-charge {
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    position: relative;
    right: -5px;
    top: -20px;
    white-space: nowrap;
    -ms-transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    transform: rotate(-45deg);
}
.triangle-top-left-charge {
    width: 0;
    height: 0;
    border-top: 140px solid #103252;
    border-right: 140px solid transparent;
    border-top: 30px solid #ff0000;
    border-right: 30px solid transparent;
    cursor: pointer;
	margin-bottom: -25px;
}
.img-buy-tip figure{
	float: left;
    margin: 10px 10px 0;
    margin: 10px;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    background-color: #dadada;
    text-align: center;
    line-height: 73px;
}
.img-buy-tip figure img{
	height: 100%;
    width: 100%;
	border-radius: 50%;
}
#buy-tip-ct{
	border: 1px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
}
#buy-tip-ct .name-tips{
	font-weight:600;
}
#buy-tip-ct .fl-r{
	text-align:right;
}
#buy-tip-ct .lh22{
	line-height:22px;
}
#buy-tip-ct .txt-cst{
	font-weight:600;margin-bottom:0px;
}
#buy-tip-ct .pr-txt-r{
	margin: 5px 0;
}
#buy-tip-ct .name-buyer{
	font-weight: 600;
    font-size: 15px;
}
.tipster-list-buy{
	margin-top:15px;
	font-family: Arial, Verdana, Helvetica, Sans-Serif !important;
}
.txt-time-see-tip{
	margin: 10px;
    padding: 15px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}
.tipster-list-buy .pttt{
	line-height: 32px;
    font-weight: 600;
}
.tipster-list-buy #selec-tt{
	font-weight:600;
}
.tipster-list-buy .tx-r{
	text-align:right;
}
.tipster-list-buy .mg2o {
	padding-right:0;
}
#btn-save-buy-tip {
    width: 100px;
    text-align: center;
    padding: 6px;
    font-size: 15px;
    font-weight: 600;
}
.ct-invoice{
	text-align:center;
}
.inv-title{
	font-size: 36px;
    font-weight: 600;
}
.inv-no{
	font-weight:600;
	font-size: 14px;
}
.line-1px{
	border-bottom:1px solid #ddd;
	margin:5px 0px;
}
.div-bdr{
	border:1px solid #ddd;
}
.di-ct-iv{
	border: 0px!important;
    padding-left: 0;
    padding-right: 0;
}
.txt-r-r{
	text-align:right;font-weight:500;
}
.ct-r-1{
	margin-top: 10px;
	margin-bottom: 10px;
}
.txt-al-r{
}
.txt-b{
	font-weight: 500;
    font-size: 16px;    
	padding-left: 15%;
}
.ct-name-pson{
	margin-top:20px;
}
.txt-tk-l{
	font-size:16px;
	font-weight:500;    
	text-align: left;
}
.ic-cpi{
	font-size:18px;
	margin-right: 10px;
}
.txt-tk-r{
	text-align:left;padding:0;
	
}
.name-per-iv{
	font-weight:500!important;
	margin-bottom: 0;
	font-size:16px;
	
}
#btn-send-iv{
	border-radius: 0; 
    line-height: 30px;
}
.iv-ft{
	text-align:center;
	margin-top:10px;
}
.fileContainer {
    background: #fcfcfd!important;
}
.ic-exa{
	color: #ffab00;
    font-size: 16px;
	margin-right:5px;
}
.lb14{
	font-size:14px;
}
#tab-buy .nav-tabs>li.active>a, #tab-buy .nav-tabs>li.active>a:focus, #tab-buy .nav-tabs>li.active>a:hover {
    color: #c90a0a!important;
    cursor: default;
}
#tab-buy .nav-tabs>li>a {
    font-weight: 600;
	text-transform: uppercase!important;
	color:#1f595d!important;
}
#tab-buy .nav-tabs {
    background-color: inherit!important;
	border: 0px;
    margin-bottom: 5px;
}
.ic-if0{
	font-size: 16px;
    color: blue;
	cursor:pointer;
}
#tab-buy .tbl-tipters>tbody>tr>td {
    vertical-align: middle;
    border-right: 1px solid #ddd;
}
.fa-paperclip{
	font-size:14px;
}
.btn-approve{
	font-size: 13px!important;
    text-transform: capitalize!important;
}
.btn-approve:hover, .btn-approve:active, .btn-approve:selected{
	border:0!important;
}
.btn-approve:focus, .btn-view:focus {
    outline: -webkit-focus-ring-color auto 0px;
	border: 1px solid #ef9795!important;
}
.lnk-inv:hover{
	text-decoration: underline!important;
	
}
.txt-norn{
	text-transform:capitalize;
}
.btn-view {
    font-size: 13px!important;
    text-transform: capitalize!important;
	width: 95px;
}
.mgec-tip{
	margin-top:15px;
}
.tips-exchange .tbl-tipters>tbody>tr>td {
    vertical-align: middle;
    border-right: 1px solid #ddd;
}
.tips-exchange .tbl-tipters{
	border: 1px solid #ddd;
}
.btn-buy-tips{
	width: 100%;
    margin: 0;
    padding: 9px;
	cursor:pointer;
}
.flr-date{
	width:32%;display: inline-block;
}
.flt-ct{
	width:58%;display: inline-block;text-align:right;
}
.ic-rig{
	width:10%;display: inline-block;
}
.lb-date{
	padding-right:5px;font-size: 14px;
}
.flr-date input{
	width: 68px;
    border-radius: 5px;
    text-align: center;
    color: #04978b;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: 600;
}
.slct-seler{
	padding: 3px;
    width: 88px;
    display: inherit;
    height: 31px;
}
#tips-sumary{
	padding:10px 0px;
}
#tbl-summary{
	border: 1px solid #ddd;
}
#tips-sumary .header {
    text-align: center;
    background-color: #04978b;
    float: left;
    width: 100%;
    text-transform: uppercase;
    border-radius: 5px 5px 0 0;
    color: #fff;
}
#tips-sumary .w-0, #tips-sumary .w-1, #tips-sumary .w-2, #tips-sumary .w-3 {
	width: 100px;
    float: left;
    min-height: 20px;
    text-align: center;
    font-size: 12px;
}
#tips-sumary .ctn {
    float: left;
    width: 100%;
	border: 1px solid #ddd;
    border-top: 0px;
    border-bottom: 0px;
}
#tips-sumary .ctn .sitem {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 5px 0;
}
#tips-sumary .ctn div.w-2 {
    width: 250px;
    min-height: 33px;
    float: left;
    text-align: left;
    font-size: 12px;
}
.tab-tip-sum .tab-content {
    border: 0px solid #ddd;
}
.tab-tip-sum #tab-buy-pane-key1, .tab-tip-sum #tab-buy-pane-key2{
	border: 1px solid #ddd;
}
.ri-tool{
	display: inline-block;
    float: right;
	width:25%;
}
.lef-text{
	width: 65%;
    text-align: left!important;
    display: inline-block;
}
.ri-tool .left-ft-admth{
	margin: 0px;
    padding: 0 15px;
    width: 183px;
    line-height: 34px;
	margin-right: 5px;
}

.ri-tool #price_mth {
    width: 55px;
}
.copied{
	float: right;
    font-size: 14px;
    cursor: pointer;
}
.copi-stk{
	padding-top:5px;
	padding-left:15px;
}
.ft-pf-chage{
	margin-bottom:8px!important;
}
.tile-er{
	font-size: 16px;
    padding-top: 10px;
    color: #15c8d4;
}

/*================================*/
@media (min-width: 1200px)
{
	.container {
		width: 1170px!important;
	}
}

