.login {
    background-color: #fff;
    float: left;
    width: 100%;
    padding-bottom: 15px;
}
.heder-page {
    background-color: #f6f6f6;
    padding: 30px 0;
    margin-bottom: 10px;
}
.heder-page .lbl-1 {
    text-align: right;
    margin-right: 100px;
}
.heder-page .item {
    float: left;
}
.heder-page h1 {
    margin: 0;
    padding: 0;
    font-size: 20px;
}
.heder-page .lbl-1 .txt {
    color: #1f595d;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    position: relative;
}
header, footer, hgroup, nav, section {
    display: block;
}
#loginForm {
    border-right: solid 2px #c8c8c8;
    float: left;
    width: 51%;
    padding: 0 20px;
}
#loginForm h2 {
    font-size: 13px;
	font-weight:600;
}

#loginForm input {
    width: 250px;
}
#loginForm  fieldset input {
    border: 1px solid #e2e2e2;
    background: #fff;
    color: #333;
    font-size: 1.2em;
    margin: 5px 0 6px 0;
    padding: 5px;
}
fieldset {
    border: none;
    margin: 0;
    padding: 0;
}
fieldset legend {
    display: none;
}
fieldset ol {
    padding: 0;
    list-style: none;
}
fieldset ol li {
    padding-bottom: 5px;
}
#loginForm ol li label {
    float: left;
    width: 126px;
    text-align: right;
    margin-right: 18px;
    font-size: 12px;
    font-weight: 500;
    line-height: 40px;
	display:block;
}
#loginForm input[type="checkbox"], #loginForm input[type="submit"], #loginForm input[type="button"], #loginForm button {
    width: auto;
}
#RememberMe {
    margin-top: 15px!important;
}
label.checkbox {
    display: inline;
}
#loginForm ol li label {
    float: left;
    width: 126px;
    text-align: right;
    margin-right: 18px;
    font-size: 12px;
    font-weight: 500;
    line-height: 40px;
}#loginForm ol li label.checkbox {
    line-height: 23px !important;
}
#loginForm input[type="submit"], #loginForm input[type="button"], #loginForm button, #loginForm a.button {
    border: solid 1px #dddddd;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    text-transform: uppercase;
    font-size: 10px!important;
    padding: 5px 15px;
    background-color: #04978b;
}
#loginForm input[type="checkbox"], #loginForm input[type="submit"], #loginForm input[type="button"], #loginForm button {
    width: auto;
}
#socialLoginForm {
    margin-left: 40px;
    float: left;
    width: 25%;
}
.social h2 {
    font-size: 13px;
}
#socialLoginForm h2 {
    margin-bottom: 5px;
	font-weight:600;
}
.externall-login .item-ex {
    float: left;
    width: 100%;
    margin: 10px 0 0 0;
}
.externall-login .item-ex a {
    color: white;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    display: inline-block;
}
.externall-login .item-ex a.login-facebook {
    background: #3e5c98;
}
.login .externall-login .item-ex a .fa {
    display: inline-block;
    border-right: solid 1px black;
    padding: 8px 0;
    width: 30px;
    text-align: center;
    margin-right: 10px;
}
.externall-login .item-ex a.login-google {
    background: #e04a39;
}

.btn-login {
    margin-left: 144px;
    border: none !important;
    background-color: #437477 !important;
    color: white;
    padding: 10px 25px !important;
}
.btn-login:hover {
    background-color: #05978b !important;
    color: #fff;
}
p {
    margin: 0;
    padding: 0;
}
.return-register {
    margin-left: 144px;
    margin-top: 15px;
    font-style: italic;
}
.return-register a,.return-register .rg {
    color: #05978b;
}
.register .regis-ctn {
    background-color: white;
    border-top: none;
    float: left;
	width:100%;
}
.page .items-1, .page .items-2, .page .items-4 {
    float: left;
    width: 100%;
    border-bottom: solid 1px #dddddd;
    padding: 20px;
}
.page .items-3{
    float: left;
    width: 100%;
    padding: 20px;
}
.page .items-1 .sub-item-1 {
    float: left;
    width: 50%;
    padding-right: 20px;
    border-right: solid 1px #ccc;
}

.page .item .lbl{
	float:right;padding-top: 9px;
}
.page .pdr0{
	padding-right:0px;
}
.page .form-group{
	margin-bottom:10px;
}
.page .item .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.page .item .item-l-1{
	border-right: solid 1px #ccc;
    padding-right: 16%;
}
.avatar1 {
    border-radius: 50% !important;
    height: 80px!important;
    height: 100%!important;
    width: 80px!important;
    width: 100%!important;
    padding: 0;
    object-fit: cover;
    overflow: hidden;
}
img {
    vertical-align: middle;border:0;
}
.avatar-container, .profile-container .file-upload-container>div {
    text-align: center !important;
}
#process-container {
    background-color: #f5f5f5;
    border: 1px solid #dcdcdc;
    font-weight: bold;
    overflow-y: hidden;
    padding: 4px 4px 4px 8px;
    /* max-width: 448px; */
    max-width: 100%;
    position: relative;
    line-height: 21px;
    margin-bottom: 5px;
    margin-top: 5px;
}
#process-container .filename {
    font-size: 13px;
    font-weight: 600;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-right: 10px;
    color: #15c;
}

#process-container .remove {
    cursor: pointer;
    height: 16px;
    margin-top: 1px;
    opacity: .5;
    width: 16px;
    position: absolute;
    right: 0;
    top: 5px;
}
#uploadAvartar{
	display:none;
}
.page #borderR{
	border-right: solid 1px #ccc!important;
    height: 170px!important;
}
.page span.note {
    display: inline-block;
    margin-left: 245px;
    font-style: italic;
    font-size: 11.5px;
    font-size: 11px;
	color:#9E9E9E;
}
.page .items-3 label {
    /*margin-bottom: 15px;*/
	display: block;
    font-size: 1.2em;
    font-weight: 700;
	font-size:16px;
}
.page .items-3 .sub-item {
    float: left;
    width: 100%;
    margin: -4px 0;
}
.page .sub-item label {
	display: block;
    font-size: 1.2em;
    font-weight: 400;
	
}
.btn-sigin {
    border: none !important;
    background-color: #437477 !important;
    color: white;
    padding: 10px 25px !important;
}
.btn-sigin:hover {
    background-color: #05978b !important;
    color: #fff;
}
.tex-center{
	text-align:center;
}
.avatar-container figure {
    float: left;
    margin: 10px;
    margin-bottom: 0px;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #dadada;
    text-align: center;
    line-height: 73px;
}
#process-container{
	left:-45px;
}
.msg-error{
	color:red;
}
.checkbox input{
	margin-top:2px;
}
#match-captions .input-icon{
	position: inherit!important;
	margin-top:7px;
}
#match-captions .search-match{
	right: 0px;
}
.page .item .form-control{
	border-radius:5px;
}
.page .item .fa-check{
	color: #09c710;
	padding-left:5px;
	padding-right:10px;
}
.page .item .fa-times{
	color: #f55050;
	padding-left:5px;
	padding-right:10px;
}
.male .radio-inline {
    font-weight: 600;
}
.overView-birthday .btn{
	float: right;
    margin-bottom: 10px;
}
.overView-birthday .body-ct{
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 10px;
    padding-bottom: 10px;
}
.overView-birthday{
	max-width:380px;
}	
.oddsF label{
	font-size:14px!important;
}
.portlet.box.green {
    border: 1px solid #39aeb5;
    border-top: 0;
}
.portlet.box.green>.portlet-title, .portlet.green, .portlet>.portlet-body.green {
    background-color: #17a2b8;
}
.portlet.box>.portlet-title {
    border-bottom: 0;
    padding: 0 10px;
    margin-bottom: 0;
    color: #fff;
}
.portlet.box>.portlet-title>.caption {
    padding: 11px 0 9px;
    font-weight: 600;
}
.portlet.box>.portlet-body {
    background-color: #fff!important;
    padding: 20px;
    min-height: 30px;
}
.mgbt10{
	margin-bottom:10px;
}
.txtR{
	text-align:right;
}
.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.mgl5{
	margin-left:5px;
}
.txtL{
	text-align:left;
}
.lh32{
	line-height:32px;
}
.error1, .error2{
	color:red;
}
.styiN{
	display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.link{
	color: #1736dc;
    text-decoration: underline!important;
}
.rc-anchor-light.rc-anchor-normal {
     border: 0px solid #d3d3d3!important; 
}
.g-recaptcha{
transform:scale(0.88);
-webkit-transform:scale(0.88);
transform-origin:0 0;
-webkit-transform-origin:0 0;
}
.note-text{
	font-size: 11px;
    color: #8a6d3b;
    font-style: italic;
	font-size: 11.5px;
    color: #9E9E9E;
}
.div-left{
	width:55%;
	display:inline-block;
}
.div-right{
	width:40%;
	display:inline-block;
	font-size: 12px;
    vertical-align: super;
	color: #337ab7;
}
.bnt-send-code{
	font-size: 14px!important;
    text-transform: inherit!important;
    padding: 7px 20px!important;
	width: 106px;
    text-align: center;
}
.txt-scon{
	color: #b3b3b3;
    font-size: 18px;
    line-height: 36px;
}
.page .items-ads {
    float: left;
    width: 100%;
    border-top: solid 1px #dddddd;
    border-bottom: solid 1px #dddddd;
    padding: 20px;
}
.item-btm{
	padding:15px;
}
.return-register a:hover{
	text-decoration:underline!important;
}
input[type="submit" i]:focus {
    border-color:#fff!important;
	outline: -webkit-focus-ring-color auto 0px;
}
.txt-note-red{
	color: red;
    font-size: 11px;
    padding-top: 5px;
}
.btn-chk-url{
	line-height: 20px;
    font-size: 11px!important;
    padding: 7px!important;
    width: 86px;
}