.friens-box{
	display:none;
	background-color: white;
    border: solid 1px #dddddd;
    position: absolute;
    top: 35px;
    right: -10px;
    width: 440px;
}
.friens-top{
	overflow: hidden; display: block;
}
.top-friend .friens-top {
    /*background-color: white;
    border: solid 1px #dddddd;
    position: absolute;
    top: 27px;
    right: -10px;
    width: 440px;
    display: none;*/
}
.friens-box:before {
    top: -8px;
    border-style: solid;
    border-color: transparent transparent white;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    right: 15px;
}
.top-friend .friens-top .frd-top {
    max-height: 500px;
    overflow-x: hidden;
}
.top-friend .friens-top .footer {
    background-color: white;
    text-align: center;
    margin: 0px;
    /*padding: 8px;*/
    border-top: solid 1px #dddddd;
}
.top-friend .friens-top .footer a {
    font-weight: 700;
    color: #1f595d;
}
.ft-items h3 {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    border-bottom: solid 1px #dddddd;
    padding: 10px 0 10px 10px;
    float: left;
    width: 100%;
    background-color: white;
}
.top-friend .friens-top .empty {
    text-align: center;
    border-bottom: solid 1px #cccccc;
    display: block;
    padding: 10px 0;
    float: left;
    width: 100%;
}

.list-gray {
    background-color: #dddddd;
}
.ft-items .ctn .ft-item {
    float: left;
    width: 100%;
    border-bottom: solid 1px #dddddd;
    padding: 5px 0;
}
.ft-items .ctn .ft-item figure {
    vertical-align: middle;
    margin: 0 10px;
}
.friens-top .ft-items .ctn .ft-item figure {
	width:50px;
	height:50px;
}
.ft-items .ctn .ft-item > div {
    margin: 0 10px;
}
.ft-items .ctn .ft-item .name {
    vertical-align: middle;
    margin-top: 10px;
    width: 100px;
}
.ft-items .ctn .ft-item .name span, .ft-items .ctn .ft-item .name .profile-name {
    font-weight: 700;
    color: #1f595d;
	font-size:14px;
}
.ft-items .ctn .ft-item .g-btn {
    margin-top: 10px;
}
.ft-items .ctn .ft-item .g-btn a {
    float: left;
    padding: 3px 5px;
    border-radius: 3px;
    opacity: .8;
    cursor: pointer;
    margin: 0 5px;
    position: relative;
}
.ft-items .ctn .ft-item .g-btn a.btn-2 {
    background-color: rgb(245, 246, 247);
    border: solid 1px #ced0d4;
    color: #030104;
}
.ft-items .ctn .ft-item .g-btn a.btn-1 {
   color: white;
    background-color: #1f595d;
    border: solid 1px #4c7a7d;
	   
}
img.loading {
    position: absolute;
    left: 6px;
    border-radius: 50%;
    top: 8px;
    display: none;
}
.ft-items h3 {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    border-bottom: solid 1px #dddddd;
    padding: 0px 10px;
	text-align:left;
    float: left;
    width: 100%;
    background-color: white;
}
.sp_QO36LKJrAiM.sx_1f63e7 {
    width: 12px;
    height: 12px;
    background-position: -9px -113px;
}
._4jy0 .img {
    bottom: 1px;
    position: relative;
    vertical-align: middle;
}
i.img {
    -ms-high-contrast-adjust: none;
}
.sp_QO36LKJrAiM {
    background-image: url(https://www.coopbet.com/media/icaf.png);
    background-size: auto;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    width: 16px;
}
._3-8_ {
    margin-right: 4px;
}
.friens-box .scroller {
    min-height: 400px!important;
    height: inherit!important;
    /*max-height: 515px!important;*/
    overflow-y: auto!important;
    overflow-x: hidden;outline: none;
	
}
.friens-box .scroller ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #1f595d;
    -webkit-box-shadow: inset 0 0 6px white;
}
.friens-box .scroller ::-webkit-scrollbar {
    width: 6px!important;
}
.friend-body{
	margin-top: 15px;
	margin-bottom:15px;
}
#friend-request, #friend-know{
	border-radius:5px;
	border: 1px solid #ddd;
	background:#fff;
	border-bottom: none;
}
.tfd-addfriend-gray{
	background-color: #f5f6f7!important;
    border-color: #ccd0d5!important;
    color: #4b4f56!important;
}
.sp_88nz5MexVSt.sx_7ffadc {
    width: 12px;
    height: 12px;
    background-position: 0 -329px;
}
.sp_88nz5MexVSt {
    background-image: url(https://www.coopbet.com/media/f9u.png);
    background-size: auto;
    background-repeat: no-repeat;
    display: inline-block;
    height: 16px;
    width: 16px;
}
#friend-request .list-gray {
    background-color: #dddddd;
    margin-bottom: 10px; 
    padding: 7px; 
}
._hdf {
    margin-bottom: 15px;
    min-height: 45px;
    padding-top: 16px;
    background-color: rgb(245, 246, 247)!important;
    border-bottom: 1px solid rgb(211, 214, 219)!important;
    border-radius: 2px 2px 0px 0px;
    font-size: 14px!important;
}
.pd0{
	padding:0px;
}
.mg10{
	margin:10px;
}
.ft-item-bst{
	width: 47%!important;
    border: 1px solid #e9ebee;
    padding: 0px!important;
    margin: 1%;
}
._rv {
   /* height: 100px;
    width: 100px;*/
	height:100%;	
	width: 100%;
    margin: auto;
    vertical-align: middle;
    text-align: center;
    object-fit: cover;
}
.name-fr{
	    vertical-align: middle;
}
.fr-it-body{
	padding:30px 0px;
}
.fwb{
	    font-weight: 600;
}
.fa-it{
	font-weight:600;
	padding-right:5px;
}
.ft-item-bst figure {
    vertical-align: middle;
    margin: 0px!important;
    margin-right: 10px!important;
	width:100px;
	height:100px;
}
.dr-fr{
	display: block;
    border: 1px solid #ccc;
    top: 65px;
    float: right;
	right: inherit !important;
	display:none;
}
.dr-fr:before {
    top: -8px;
    border-style: solid;
    border-color: transparent transparent #fff;
    border-width: 0 8px 8px;
    content: "";
    position: absolute;
    margin: auto;
    z-index: 2;
    left: 15px;
}
.fiend-box-top{
	display: flow-root;
}
.count-fr{
	color: #90949c;
	font-size:12px;
}
#modal-like-list .scroller {
    min-height: 300px !important;
    height: inherit !important;
    max-height: 425px!important;
    overflow-y: auto!important;
    overflow-x: hidden;
	    outline: none;
}
#modal-like-list img{
	border-radius:50%;
}
#modal-like-list .ft-items .ctn .ft-item {
    padding: 5px 0;
}
#modal-likes .modal-body {
    padding: 0px 15px 0px 15px;
}
.userpl{
	padding-right: 5px;
    color: #4b4f56;
    font-size: 11px;
}
.fw600{
	font-weight:600!important;
}
.pdr5{
	padding-right:5px;
}
.icon-friend{
	font-size: 20px;
    color: #adb3bc;
    vertical-align: middle;
    padding-right: 5px;
}
.tl-flow{
	margin-left:5px;
}
.txt-red{
	color:#c90a0a;
}
.btn-a{
	background-color: #f5f6f7;
    color: #1f595d;
    font-weight: 600;
}
.line-h{
	height:40px;
	border-left: 1px solid #aeaeae;
}
.fr-it-body .add-friend {
    background-color: rgb(4, 151, 139)!important;
    border: solid 1px #ffffff!important;
    color: #ffffff!important;
}
.mrgr3{
	margin-right:3px;
}
#friend-gruop .header{
	height: 30px;
    line-height: 26px;
	font-size:12px;
}
.friens-top .footer{
	line-height: 30px;
	font-size:12px;
}
.ft-items .ctn .ft-item .name span{
	color: rgb(51, 51, 51);
    font-size: 13px;
	
}
.ft-items .ctn .ft-item .g-btn a.btn-2, .ft-items .ctn .ft-item .g-btn a.btn-1{
	font-size:12px;
}
.cr-pnt{
	cursor:pointer;
}
.item img.loading{
	width:20%;
}
.txt-fmf{
	color: hsla(0,0%,7%,.6);
    font-size: 13px;
    font-weight: 400;
    text-transform: none;
}
