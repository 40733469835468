
.tipster-list .items {
    float: left;
    width: 100%;
    border: solid 1px #dddddd;
    border-top: none;
}
.tipster-list .items:first-child {
    border-top: solid 1px #dddddd;
}
.tipster-list .items .item {
    float: left;
    margin-right: 25px;
    margin-right: 5px!important;
}
.tipster-list .items .sort {
    border-right: solid 1px #dddddd;
    line-height: 100px;
    text-align: center;
    padding: 0 10px;
}
.tipster-list .items figure {
    line-height: 70px;
    border-radius: 50%;
}
.tipster-list .items .item {
    float: left;
    margin-right: 25px;
}
.tipster-list .items figure img {
    border-radius: 50%;
}
.tipster-list .items .name {
    width: 15%;
}
.tipster-list .items .name h3 {
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 0px;
}
.tipster-list .items .name span.lbl {
    text-transform: uppercase;
    font-size: 10px;
}
.tipster-list .items .name span.ctn {
    background: url('https://www.coopbet.com/media/icontipsterctn.png') no-repeat;
    padding-left: 24px;
}
.tipster-list .items .profit {
    margin-top: 15px;
    width: 25%;
}
ul li {
    list-style: none;
}
.tipster-list .items .profit li b {
    width: 100px;
    display: inline-block;
    text-align: right;
    margin-right: 10px;
}
.tipster-list .items .sport-tip {
    background: url('https://www.coopbet.com/media/iconsoccerball.png') no-repeat top center;
    padding-top: 29px;
    text-transform: uppercase;
    color: #04978b;
    font-size: 10px;
    margin-top: 30px;
}
.tipster-list .items .edit {
    float:right;
    padding-top: 10px;
    text-transform: uppercase;
    color: #04978b;
    font-size: 10px;
}
.tipster-list .items .edit span {
	font-size:20px;cursor: pointer;
}
.table_bound {
    background-color: #fff;
	width: 100%;
	display:block;
}
table.common  {
    border-collapse: collapse;
    border: 1px solid #dddddd;
    border-top: none;
    width: 100%;
}
table.common thead td {
    border: none;
    padding: 14px 0;
}

table.common thead {
    background: #10b2a5;
    color: white;
}
table.common thead td {
    border: none;
    padding: 14px 10px;
}

.approve:hover, .approve:active {
    border: solid 1px #05978b;
    padding: 8px 15px;
    color: black;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    background-color: #05978b;
	color:#fff;
}
.approve{
	padding: 8px 15px;
    color: black;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px #05978b; 
    color: #05978b;
	background-color: #fff;
}
.search-user{
	color: #333;
    display: block;
    position: absolute;
    margin: 8px 2px 4px 10px;
    z-index: 3;
    width: 16px;
    height: 16px;
    font-size: 16px;
    text-align: left;
    right: 8%;
    float: right;
    cursor: pointer;
}
.div-search-user{
	width: 100px;
    display: inline-block;
    float: right;
}
.input-search{
	border-radius:10px;
}

.search-match{
    color: #333;
    display: block;
    position: absolute;
    margin: 8px 2px 4px 10px;
    z-index: 3;
    width: 16px;
    height: 16px;
    font-size: 16px;
    text-align: left;
    right: 8%;
    float: right;
    cursor: pointer;
}

.div-search-match{
	width: 30%;
    display: inline-block;
    float: right;
}
.tool-r{
	width: 70%;
    float: right;
}
.select-search{
	width: 25%!important;
    float: right;
    margin-right: 5px;
    border-radius: 10px;
}
.input-icon-search{
	width: 40%!important;
    float: right;
	
}
.input-result{
	width: 40px;
    display: inline;
	margin:0px 5px;
	padding:5px;
}
.apr-all{
	padding:8px!important;
}
.apr-all:hover,.apr-all:active{
	border: solid 1px #ffffff;
    color: #ffffff;
    background-color: #1f595d7d;
}
.daterange {
    width: 20%;
    display: inline-block;
    float: right;
}
#daterange .input-date{
	width:150px!important;
	border-radius: 7px;
    height: 34px;
	outline: none;
	padding:15px;
}
/*-----calendar*/
.daterange .react-datepicker__day-name, .daterange .react-datepicker__day, .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 2.7rem;
    line-height: 2.7rem;
    text-align: center;
    margin: 0.166rem;
    font-size: 12px;
}
.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #4a9095!important;
}
.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #4a9095!important;
}
.react-datepicker__current-month, .react-datepicker-time__header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 1.2rem!important;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #10b2a5!important;
    color: #fff!important;
}
/*----loader*/
.loader {
  position: absolute;
  left: 60%;
  top: 100%;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: -75px 0 0 -75px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/*------------*/
/*-------scroller----*/
.scroller::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(27, 25, 25, 0.84);
    border-radius: 10px;
	
}

.scroller::-webkit-scrollbar-thumb {
   
	background-color: rgb(255, 255, 255);
    background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#ccc), color-stop(0.6, #ccc), to(#ccc));
    border-radius: 10px;
}

.scroller::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fafafa;
}
.scroller {
    min-height: 400px !important;
    height: inherit !important;
    max-height: 800px!important;
    overflow-y: auto!important;
	overflow-x: hidden;
}
.div-search-user .input-icon{
	width:100%;
	position:initial!important;
}
.div-search-user .search-user{
	right:0px;
}
.admin-search-user{
    width: 100%;
    padding-left:25%;
    padding-right:25%;
}
.admin-search-user > table {
    width: 100%;
    padding: 5px;
}
.admin-search-user > table > tbody > tr > td{
    padding: 5px 10px;
    width:30%;
}
.admin-search-user > table > tbody > tr > td > .firstcol{
    padding: 5px 10px;
    width:30%;
}
.firstrow {
    background-color:#05978b;
    color:#dddddd;
}
.headerrow > td{
    background-color:#eaeaea;
    border-top: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
}
.secondrow {
    border: 1px solid #000;
}
.secondrow > td{
    border-left: 1px solid #f3f3f3;
}
.secondrow > td:first-child{
    border-left: 1px solid #000;
}
.normalrow {
    border: 1px solid #000;
}
.sincedate {
    float: left;
    width: 200px;
}
.sincedatetext{
    float: left;
    padding: 10px 0px;
    width: 150px;
    height: 35px;
}
.tdsearch{
    text-align: center;
}
.userlistheader{
    padding: 5px 10px;
    background-color:#05978b;
    color:#dddddd;
}
.editbutton{
    width:100%;
    text-align: center;
    padding: 10px 0px;
    padding: 3px 0px;
}

.norecord{
    padding: 5px 10px;
}
.items-1b{
    float: left;
    width: 100%;
    border-bottom: solid 1px #dddddd;
    padding: 20px;
}
#uploadBanner{
    display:none;
}
#img-banner .avatar-container figure {
    float: left;
    margin: 10px;
    margin-bottom: 0px;
	margin-left:0px;
    height: 160px;
    width: 260px;
    border-radius: 0;
    background-color: #dadada;
    text-align: center;
    line-height: 0;
}
#img-banner .avatar1 {
    border-radius: 0% !important;
    height: 160px!important;
    width: 260px!important;
    padding: 0;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}
#img-banner #process-container {
    left: 0;
}
#img-banner .ctnavatar-container{
    padding: 5px 25px;
}
#buttonUploadBanner{
    padding-left:50px;
}
#uploadAdUserLogo, #uploadBannerMobile{
    display:none;
}
#img-aduserlogo .avatar-container figure {
    float: left;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
	margin-left:0px;
    height: 50px;
    width: 100px;
    border-radius: 0;
    background-color: #dadada;
    text-align: center;
    line-height: 0;
}
#img-aduserlogo .avatar1 {
    border-radius: 0% !important;
    height: 50px!important;
    width: 100px!important;
    padding: 0;
    -o-object-fit: cover;
    object-fit: cover;
    overflow: hidden;
}
#img-aduserlogo #process-container {
    left: 0;
}
#img-aduserlogo .ctnavatar-container{
    padding: 5px 25px;
}
#buttonuploadAdUserLogo{
    /*padding-left:50px;*/
}

.msg-error,.msg-succesfull{
    text-align: center;
}
.admin-input-introduction{
    height: 170px!important;
}

.addmatch{
    padding: 5px 10px;
    color: black;
    margin-left: 5px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px #05978b; 
    color: #05978b;
    background-color: #fff;
}
/*-------------*/