.pdt5{
    padding-top:5px;
}

.kickofftime {
    width: 20%;
    display: inline-block;
    float: left;
}

.colleft {
    float: left;
}
.divw65 {
    width: 65px;
}
.textleft {
    text-align: left;
    padding-left: 10px;
}
#contest{
	margin-bottom:20px;
	min-height:300px;
}
.list-legues-eruo{
	min-height: 150px;
    border: 1px solid #ddd;
}
#sho_hide .checkbox{
    margin-top: 5px;
    margin-bottom: 5px;
}
.ct-contets{
	padding: 5px;
    cursor: pointer;
}
.ct-contets:hover{
	background:#ccc;
}
.ct-contets.selected{
	background:#ccc;
}
.succes{
	color:green;
}
.error{
	color:red;
}