.heeader-profile{
	background:#fff;
	border: 2px solid #1f595d;
	border: 1px solid #d3d6db;	
    border-radius: 0 0 3px 3px;
	margin-bottom: 20px;
	margin: 88px 0 0 0;
	height:auto;

}
/*@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,400,700);*/


.navbar {
    border-radius: 0;
    margin-bottom: 0;
    border: none;
    font-family: 'Open Sans Condensed', sans-serif, sans-serif;
}

.navbar > li >a {}

.navbar-header {}

.navbar-brand {
    width: 140px;
    height: 150px;
    float: left;
    padding: 0;
    margin-top: -125px;
    overflow: hidden;
    border: 3px solid #eee;
	border-radius:100%;
    margin-left: 15px;
    background: #333;
    background: #dadada;
    text-align: center;
    line-height: 160px;
    color: #fff !important;
    font-size: 2em;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.site-name {
    color: #fff;
    font-size: 1.8em;
    float: left;
    margin-top: -55px !important;
    margin-left: 15px;
    font-family: 'Open Sans Condensed', sans-serif, sans-serif;
}

.site-description {
    color: #fff;
    font-size: 1.3em;
    float: left;
    margin-top: -30px !important;
    margin-left: 15px;
}

.main-menu {
    position: absolute;
    left: 260px;
	border-left: 1px solid #d3d6db;
	border-left: 1px solid #9e9e9e91;
}
.main-menu>li {
    border-right: 1px solid #d3d6db;
    border-right: 1px solid #9e9e9e91;
    width: 160px;
    text-align: center;
}
.slider,
.carousel {
    max-height: 360px;
    overflow: hidden;
}

.carousel-control .fa-angle-left,
.carousel-control .fa-angle-right {
    position: absolute;
    top: 50%;
    font-size: 2em;
    z-index: 5;
    display: inline-block;
}

.carousel-control {
    background-color: transparent;
    background-image: none !important;
}

.carousel-control:hover,
.carousel-control:focus {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
    background-image: none !important;
    outline: 0;
}
.navbar-default .navbar-nav>li>a {
    color: #1f595d;
    font-weight: bold;
	font-size:15px;
	font-weight: 600;
	font-size:14px;
}
.slider, .carousel {
    max-height: 360px;
    overflow: hidden;
    min-height: 130px;
}
.site-name {
	color: #1f595d;
}

.site-description {
	color: #00fcfd;
}
.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover,.navbar-nav>li>a.active {
    color: #c90a0a;
    background-color: transparent;
}
.navbar-default{
	border-top: 1px solid #e7e7e7;
	background-color:#f5f6f7;
}
.item img{
	width:100%;
}
#profile-friend #friend-request{
	border: 1px solid #ddd!important;
}
#profile-friend ._rv{
	border-radius:0!important;
}
#profile-friend .friend-body{
	margin-top:0px;
}
.item-time-line{
	/*border:1px solid #ddd;*/
	border-bottom: 0px dotted #04978b;
	margin-bottom:20px;
	padding:0px 5px 5px 5px;
}
.mg-20{
	margin-left:-20px;
	margin-right:-20px;
}
.item-time-line .header {
   background-color: #04978b!important;
    color: #fff;
	 /*background-color: rgb(245, 246, 247)!important;
    border: 1px solid rgb(211, 214, 219)!important;*/
}
/*===============Date-time==============*/
.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}
td.rdtMonth, td.rdtYear{
	color:#000;
}
.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
    color: #000;
}
.rdtPicker th.rdtSwitch {
    color: #000;
}
/*=============================*/
.bet-title input {
    width: 60px!important;
    width: 61px!important;
    text-align: center;
    padding: 3px;
	height: 25px;
}
.navbar {
    min-height: 40px!important;
}
.navbar-nav>li>a {
	padding-top: 10px!important;
	padding-bottom: 10px!important;
}


@media (max-width: 768px) {
    .navbar-brand {
        max-width: 100px;
        max-height: 100px;
        float: left;
        margin-top: -65px;
        margin-left: 15px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .navbar {
        border-radius: 0;
        margin-bottom: 0;
        border: none;
    }
    .main-menu {
        left: 0;
        position: relative;
    }
	
}

@media (max-width: 490px) {
    .site-name {
        color: #fff;
        font-size: 1.5em;
        float: left;
        line-height: 20px;
        margin-top: -100px !important;
        margin-left: 125px;
    }
    .site-description {
        color: #fff;
        font-size: 1.3em;
        float: left;
        margin-top: -80px !important;
        margin-left: 125px;
    }
}