.count-txt-cm {
    vertical-align: middle;
    font-size: 1.6rem;
    font-size: 16px;
    font-size: 15px;
    font-weight: 400;
    line-height: 2rem;
	color: #030104;
	/*margin-top: 0.5rem;
    margin-bottom: 0.5rem;*/
}
.name-comment{
	font-size: 1.4rem;
    font-weight: 600!important;
    line-height: 1.6rem;
}
.img-icon{
	margin-right: 16px;
    width: 48px!important;
    height: 48px!important;
    border-radius: 50%!important;
    background-color: transparent;
    overflow: hidden;
	border: 1px solid #eee;
}
.img-icon-sm{
	width: 40px!important;
    height: 40px!important;
    margin-right: 16px;
    -ms-flex: none;
    -webkit-flex: none;
    flex: none;
    border-radius: 50%;
    background-color: transparent;
    overflow: hidden;
	position: relative;
    margin-top: 0.5rem;
	border: 1px solid #eee;
    /*margin-bottom: 0.5rem;*/
}


.textarea-cm:focus:not([readonly]) {
    
	-webkit-box-shadow: 0 1px 0 0 #04978b;
    box-shadow: 0 1px 0 0 #04978b;
    border-bottom: 1px solid #04978b;
}
.textarea-cm{
	 -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #ced4da;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    background-color: transparent;
	width: -webkit-fill-available;
	width:100%;
}
.pdL0{
	padding-left:0px!important;
}
.t-right{
	text-align:right;
}
.bg-green-haze{    
	border-color: #44b6ae !important;
    background-image: none !important;
    background-color: rgba(4, 151, 144, 0.35);
    color: #FFFFFF !important;
    position: relative;
    /* display: block; */
    padding: 10px 15px;
    padding: 10px;
    text-transform: uppercase;
    ertical-align: middle;
    white-space: nowrap;
    font-size: 1.4rem;
    font-size: 14px;
    font-size: 12px;
    font-weight: 500;
    font-weight: 600;
    letter-spacing: .007px;
	margin-left:5px;
	    overflow: hidden;
		font-family: sans-serif;
    font-stretch: 100%;
	border-radius:1px;
	}

.bg-grey-steel{    	
    color: #80898e !important;
    position: relative;
    /* display: block; */
    padding: 10px 15px;
    padding: 10px;
    text-transform: uppercase;
    ertical-align: middle;
    white-space: nowrap;
    font-size: 1.4rem;
    font-size: 14px;
    font-size: 12px;
    font-weight: 500;
    font-weight: 600;
    letter-spacing: .007px;
	margin-left:5px;
	font-family: sans-serif;
    font-stretch: 100%;
	}
	.bg-grey-steel:focus,.bg-grey-steel:hover{
		border-color: #e9edef !important;
		background-image: none !important;
		background-color: #e9edef !important;
		color: #5e6569 !important;
	}
	.ot-btn{
		display:none;
	}
	.bg-green-haze.active{
		border-color: #44b6ae !important;
		background-color: #44b6ae !important;
		color: #FFFFFF !important;
	}
	.br-tp{
		border-top: 1px solid #ddd;
		padding-top: 15px;
	}
	.pd0{
		padding:0px;
	}
	.name-cmt{
		font-size: 1.4rem;
		font-size: 14px;
		font-weight: 600!important;
		line-height: 0.8rem;
	}
	.time-cmt{
		margin-left: 7px;
		color: rgba(17, 17, 17, 0.6);
		font-size: 1.3rem;
		font-weight: 400;
	}
	.r-like-reply{
		margin-top:10px;
	}
	.img-icon-like{
		width:18px;
		height:18px;
	}
	.img-icon-dislike{
		width:18px;
		height:18px;
		    margin-left: 20px;
	}
	.count-like{
		padding: 5px;
		padding-top: 3px;
		position: absolute;
	}
	.mgt10{
			margin-top:10px;
	}
	.reply-cmt{
		margin-left:15px;
		color: #11111199;
		font-size: 1.3rem;
		font-size: 13px;
		font-weight: 600;
		letter-spacing: .007px;
		text-transform: uppercase;
		padding-top: 3px;
		position: absolute;
	}
	.img-icon-sm24 {
		width: 24px!important;
		height: 24px!important;
		margin-right: 16px;
		-ms-flex: none;
		flex: none;
		border-radius: 50%;
		background-color: transparent;
		overflow: hidden;
		position: relative;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	.t-reply,.t-reply-child{
		display:none;
	}
	.w-5p{
		width:5%!important;
	}
	.w-95p{
		width:95%!important;
	}
	.reply-List{
		margin-top:7px;
	}
	.reply-List .fa{
		margin-left:7px;
	}
	.hide-cm{
		/*display:none;*/
	}
	.hide-cm, .view-cm{
		cursor:pointer;
	}
	img.loading_dad {
		position: absolute;
		left: 20%;
		border-radius: 50%;
		top: 0px;
		 display: none;
	}
	.img-icon-like, .img-icon-dislike{
		cursor:pointer;
	}
	.view-more{
		font-size: 1.2rem;
		font-size: 13px;
		color: #1f595d;
	}
	.view-more:hover,.view-more:focus{
		text-decoration: underline!important; 
	}
	.name-cmt, .name-comment, .view-more{
		color: #0d9488!important;
	}
	.time-cmt {
		color: rgba(142, 156, 155, 0.86);
		color: rgb(96, 96, 96);
		font-size: 1.2rem;
		font-size: 13px;
	}
	.count-txt-cm{
		cursor:pointer;
	}
	.do_cm textarea.form-control {
		height: auto;
		max-height: 21px;
	}
	.mrgbt15{
		margin-bottom:15px;
	}
	.pdt10{
		padding: 10px 0 0 0;
	}
	.dot-cmt{
		color:#c7b8b8;
		margin-left: 10px;
		font-size: x-large;
		line-height: 3px;
	}
	.dot-hv{
		cursor:pointer;
	}
	.fa-ellipsis-h{
		font-size: 1.3em!important;
		cursor: pointer;
		color: #c7b8b8!important;
		padding: 0!important;
		border: none;
		margin-left: 10px;
		vertical-align: middle;
	}
	.glyphicon-triangle-bottom,.glyphicon-triangle-top{
		display:none;
	}
	.dot-hv .show{
		padding: 12px!important;
		overflow: hidden!important;
		background-color:transparent!important;
		box-shadow: none!important;
		top: 20px!important;
	}
	.dot-hv .show .noti-box{
		padding:10px 0px;
	}
	.dot-hv .show .noti-box:before {
		top: -8px;
		border-style: solid;
		border-color: #333 #fff #ccc!important;
		border-width: 0 8px 8px;
		content: "";
		position: absolute;
		margin: auto;
		z-index: 2;
		left: 5px;
	}
	.text-cmt-tl{
		font-weight:400;
		color: #777;
	}
	
	.count-txt-cm {
		text-transform: capitalize;
	}
	.rm-dsc .show{
		top: 30px!important;
	}
	.mgbt10{
		margin-bottom:10px;
	}